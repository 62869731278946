import * as types from 'actions/action-types';

var initialState = {
    stock_parts: null,
    stock_parts_pending: false,
    stock_parts_save_pending: false,
    stock_parts_unsubscribe: false,
    
    stock_part: null,
    stock_part_pending: null,
    stock_part_save_pending: false,
    stock_part_unsubscribe: false,

    purchase_order: {},
    purchase_order_saved: null,
    purchase_order_pending: false,
    purchase_order_save_pending: false,
    purchase_order_unsubscribe: false,

    purchase_orders: [],
    purchase_orders_saved: null,
    purchase_orders_pending: false,
    purchase_orders_save_pending: false,
    purchase_orders_unsubscribe: false,

};

const StockPartsReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.STOCK_PARTS + '_PENDING': pending('stock_parts'); break;
        case types.STOCK_PARTS + '_SAVE_PENDING': save_pending('stock_parts'); break;
        case types.STOCK_PARTS + '_SAVE_FULFILLED': save_fulfilled('stock_parts'); break;
        case types.STOCK_PARTS + '_FULFILLED': fulfilled('stock_parts'); break;
        case types.STOCK_PARTS + '_CLEAR': clear('stock_parts', {}); break;

        case types.STOCK_PART + '_PENDING': pending('stock_part'); break;
        case types.STOCK_PART + '_SAVE_PENDING': save_pending('stock_part'); break;
        case types.STOCK_PART + '_SAVE_FULFILLED': save_fulfilled('stock_part'); break;
        case types.STOCK_PART + '_FULFILLED': fulfilled('stock_part'); break;

        case types.PURCHASE_ORDER + '_PENDING': pending('purchase_order'); break;
        case types.PURCHASE_ORDER + '_SAVE_PENDING': save_pending('purchase_order'); break;
        case types.PURCHASE_ORDER + '_SAVE_FULFILLED': save_fulfilled('purchase_order'); break;
        case types.PURCHASE_ORDER + '_FULFILLED': fulfilled('purchase_order'); break;
        case types.PURCHASE_ORDER + '_CLEAR': clear('purchase_order', {}); break;

        case types.PURCHASE_ORDERS + '_PENDING': pending('purchase_orders'); break;
        case types.PURCHASE_ORDERS + '_SAVE_PENDING': save_pending('purchase_orders'); break;
        case types.PURCHASE_ORDERS + '_SAVE_FULFILLED': save_fulfilled('purchase_orders'); break;
        case types.PURCHASE_ORDERS + '_FULFILLED': fulfilled('purchase_orders'); break;
        case types.PURCHASE_ORDERS + '_CLEAR': clear('purchase_orders', {}); break;

        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_saved']: action.data,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        };
    }
};
export default StockPartsReducer;


