import * as types from 'actions/action-types';

var initialState = {
	service_item: null,
	service_item_saved: null, // this allows search page to trigger search update
	service_item_pending: false,
	service_item_save_pending: false,
	service_item_unsubscribe: false,

	service_items: [],
	service_items_pending: false,
	service_items_save_pending: false,
	
	service_item_categories: null,
	service_item_categories_pending: false,
	service_item_categories_save_pending: false,
	service_item_categories_unsubscribe: false,
};	

const serviceItemsReducer = (state = initialState, action) => {

	switch(action.type) {
		case types.SERVICE_ITEM + '_PENDING': pending('service_item'); break;
		case types.SERVICE_ITEM + '_SAVE_PENDING': save_pending('service_item'); break;
		case types.SERVICE_ITEM + '_SAVE_FULFILLED': save_fulfilled('service_item'); break;
		case types.SERVICE_ITEM + '_FULFILLED': fulfilled('service_item'); break;
		
		case types.SERVICE_ITEMS + '_PENDING': pending('service_items'); break;
		case types.SERVICE_ITEMS + '_SAVE_PENDING': save_pending('service_items'); break;
		case types.SERVICE_ITEMS + '_FULFILLED': fulfilled('service_items'); break;
		
		case types.SERVICE_ITEM_CATEGORIES + '_PENDING': pending('service_item_categories'); break;
		case types.SERVICE_ITEM_CATEGORIES + '_SAVE_PENDING': save_pending('service_item_categories'); break;
		case types.SERVICE_ITEM_CATEGORIES + '_FULFILLED': fulfilled('service_item_categories'); break;
			
		default : break;
	}

	return state;

	function pending(table) {
		state = { ...state,
			[table + '_pending']: true,
		};
	}
	function add(table) {
		if (!state[table].includes(action.data)) {
			state = { ...state,
				[table]: [ ...state[table], action.data ],
				[table + '_pending']: false,
				[table + '_save_pending']: false,
			};
		}
	}
	function save_fulfilled(table) {
		state = { ...state,
			[table + '_saved']: action.data,
			[table + '_save_pending']: false,
		};
	}
	function fulfilled(table) {
		state = { ...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}
	function save_pending(table) {
		state = { ...state,
			[table + '_save_pending']: true,
		};
	}
	function clear(table, value) {
		state = {
			...state,
			[table]: value,
		}
	}

}
export default serviceItemsReducer;


