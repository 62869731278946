import moment from 'moment';
import React from 'react';
import { Avatar } from 'enspire-manager-framework';
import firebase from 'firebase/compat/app';

const ChatMessage = (props) => {
    const message = props.message;
    const user = props.user;
    const sender = props.sender;
    const senderIsUser = user.id == message.userId;
    const icon = (message.attachment?.type?.split('/')[1] == 'pdf') ? "fa-file-pdf" :
        (message.attachment?.type?.split('/')[1] == 'csv') ? "fa-file-csv" :
            (message.attachment?.type?.split('/')[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') ? 'fa-file-excel'
                : 'fa-file';

    const downloadFile = () => {
        var storage = firebase.storage();
        var httpReference = storage.refFromURL(message?.attachment?.downloadURL);
        httpReference.getDownloadURL()
            .then((url) => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    var blob = xhr.response;
                    const blobUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.download = message.attachment.filename;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                };
                xhr.open('GET', url);
                xhr.send();
            })
            .catch((error) => {
                console.error(error);
            });
    };


    return (
        <>
            <div className="clearfix mb-3">
                <div className={`message-data ${(senderIsUser) && "text-right justify-content-end"} d-flex align-items-center`}>
                    <span className="message-data-time">{moment(message.date).format("MMMM Do YYYY, h:mm:ss a")} &nbsp;</span>
                    <Avatar
                        color="white"
                        bgColor="grey"
                        size={40}
                        name={`${sender?.firstName} ${sender?.lastName}`}
                        image={sender?.photoUrl}
                        fontSize={15}
                    />
                </div>
                {message?.attachment?.downloadURL ?
                    (message.attachment.type.split('/')[0] == 'image')
                        ? <img className={`message ${(senderIsUser) ? "other-message float-right" : "my-message"}`} width='100%' src={message.attachment.downloadURL} />
                        : <>
                            <div className={`message ${(senderIsUser) ? "other-message float-right" : "my-message"}`}>
                                <a onClick={downloadFile} className='d-flex justify-content-center align-items-center'>
                                    <i className={`fa ${icon} fa-2x`}></i>&nbsp;{message.attachment.filename}
                                </a>

                            </div>
                        </>
                    :
                    <div className="clearfix mb-3">
                        <div className={`message ${(senderIsUser) ? "other-message float-right" : "my-message"}`}>{message.message}</div>
                    </div>}
            </div>
            {(message.attachment?.downloadURL && message.message) && <div className="clearfix mb-3">
                <div className={`message ${(senderIsUser) ? "other-message float-right" : "my-message"}`}>{message.message}</div>
            </div>}
        </>
    );
};

export default ChatMessage;