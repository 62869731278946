import Chat from 'components/chat-port/chat';
import HelpPanel from 'components/layout/help-panel';
import NotificationSound from 'bubble-sound-43207.mp3';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export default function Footer() {

	const [chatOpen, setChatOpen] = useState(false);
	const unreadMessages = useSelector((store) => store.chat.unread_messages);
	const [unreadCount, setUnreadCount] = useState(0);
	const audioPlayer = useRef(null);

	const playAudio = () => {
		audioPlayer.current.play();
	};

	useEffect(() => {
		const newCount = unreadMessages?.length;
		if (newCount > unreadCount) playAudio();
		setUnreadCount(newCount);

	}, [unreadMessages]);


	return (
		<>
			<HelpPanel />
			
			<div className='chat-container'>
				<Chat chatOpen={chatOpen} setChatOpen={setChatOpen} />
			</div>
			<div className="footer">
				{/*<div className="pull-right">
				10GB of <strong>250GB</strong> Free.
			</div>*/}
				<div>
					<strong>Copyright</strong> Mobile Track Systems &copy; 2024
					<button className="btn-primary btn float-right mt-0" onClick={() => { setChatOpen(!chatOpen); }} >Private Messages &nbsp;<span className="badge badge-danger">{(unreadCount > 0) && unreadCount}</span></button>
					<audio ref={audioPlayer} src={NotificationSound} />
				</div>
			</div>
		</>
	);
}
