import React, { useEffect, useState } from 'react';
import { numPanels } from 'components/common/toolbox';
import { useLocation } from 'react-router';

export default function SlidingColumns(props) {

	const routeLocation = useLocation();
	const segments = routeLocation.pathname.split('/').length;
	const screenWidth = document.body.clientWidth

	const { panels1, panels2, panels3 } = props.breakpoints;
	
	const [state, setState] = useState({
		panelWidth: 0, // Width of Sliding panels
		
		panelSlide2: 0, // number of shifts 0, 1, 2
		panelSlide3: 0,
		panelSlide4: 0,
		
		panelPos2: 0, // left position of each sliding panel
		panelPos3: 0,
		panelPos4: 0,
		
		opening: true, // direction
		prevSlide: 0,
		prevSlide2: 0,
	});

	// Send state back up to parent state
	useEffect(() => {
		props.setColumnState(state);
	}, [state]);

	useEffect(() => {
		updateSize();
	}, [routeLocation.pathname]);

	useEffect(() => {
		window.addEventListener('resize', updateSize);
		return () => { window.removeEventListener('resize', updateSize);}
	}, []);

	useEffect(() => {
		let shiftPanels = (numPanels(1)) ? panels1 : (numPanels(2)) ? panels2 : panels3;
		
		if (segments >= shiftPanels[2]) {
			slidePanel(3);
		} else if (segments >= shiftPanels[1]) {
			slidePanel(2);
		} else if (segments >= shiftPanels[0]) {
			slidePanel(1);
		} else {
			slidePanel(0);
		}
	}, [routeLocation.pathname, screenWidth]);

	useEffect(() => {
		setTimeout(() => setState(prev => ({ ...prev, panelSlide3: state.prevSlide, prevSlide2: state.prevSlide })), 5);
	}, [state.prevSlide]);
	useEffect(() => {
		if (state.opening ) {
			setTimeout(() => setState(prev => ({ ...prev, panelSlide4: state.prevSlide2 })), 5);
		} else {
			setTimeout(() => setState(prev => ({ ...prev, panelSlide2: state.prevSlide2 })), 5);
		}
	}, [state.prevSlide2]);

	const updateSize = () => {
		let panelWidth = document.getElementById('column2-rel')?.offsetWidth;
		let panelPos2 = document.getElementById('column1')?.offsetWidth;
		let panelPos3 = panelPos2 + panelWidth;
		let panelPos4 = panelPos3 + panelWidth;
		setState(prev => ({ ...prev, panelWidth, panelPos2, panelPos3, panelPos4 }));
	}
	const slidePanel = (slide) => {
		if (state.prevSlide <= slide) {
			setState(prev => ({ ...prev, panelSlide2: slide, prevSlide: slide, opening: true }));
		} else {
			setState(prev => ({ ...prev, panelSlide4: slide, prevSlide: slide, opening: false }));
		}
	}

	return (
		<div className="row">
			{ props.children }
		</div>
	);
};
