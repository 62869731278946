import 'App.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import * as actions_admin from 'actions/admin-actions';
import * as actions_authentication from 'actions/authentication-actions';
import * as actions_company from 'actions/company-actions';
import * as actions_profile from 'actions/profiles-actions';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import * as actions_service_items from 'actions/service-items-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_users from 'actions/users-actions';
import Archive from './archive/archive';
import CatchErrors from 'components/common/catch-errors';
import Dashboard from 'components/dashboard/dashboard';
import Footer from './layout/footer';
import Navbar from './layout/navbar';
import QuickbooksConnect from './quickbooks/quickbooks-connect';
import React, { useEffect, useState } from 'react';
import SideMenu from './layout/side-menu';
import Signature from './signature/signature';
import firebase from 'firebase/compat/app';
import metadata from '../metadata.json';
import { AccountDelete, PasswordReset, Spinner, UserNotFound } from 'enspire-manager-framework';
import { FLUTTER_NAV, FLUTTER_DRAWER_NAV, FLUTTER_SETTINGS_NAV, FLUTTER_TABS, ROLES } from 'components/common/constants';
import { Redirect, Route, Switch } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { numPanels, matchURL } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

// UNITL APPLE LOGIN IS FIGURED OUT
import { Login } from 'components/authentication/login';
import { Register } from 'components/authentication/register';

const Assets = React.lazy(() => import('./assets/assets'));
const Customers = React.lazy(() => import('./customers/customers'));
const Employees = React.lazy(() => import('./employees/employees'));
const Schedule = React.lazy(() => import('./schedule/schedule'));
const Settings = React.lazy(() => import('./settings/settings'));
const StockParts = React.lazy(() => import('./stock-parts/stock-parts'));
const Vendors = React.lazy(() => import('./vendors/vendors'));

export default function App(props) {
	
	const match = useRouteMatch();
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

	const users = useSelector((store) => store.users);
	const company = useSelector((store) => store.company);
	const settings = useSelector((store) => store.settings);

	const [isSinglePanel, setIsSinglePanel] = useState(false);	
    	
	/* Listeners -----------------------------------------------------------------------------------------------------------------------*/
	
	useEffect(() => {
		handleResize();
		window.addEventListener("resize", _.debounce(handleResize, 250));
	}, []);

	useEffect(() => {
		window.addEventListener("platform", (event) => {
			dispatch(actions_admin.flutterPlatform(event.detail.platform));
		});
	}, []);

	useEffect(() => {
		window.addEventListener("fabBarcodeScanned", (event) => {
			dispatch(actions_admin.barcodeToUrl(params.handle, event.detail.barcode, (url) => {
				if (url) {
					history.push(url);
				} else {
					window.toastr.error('The barcode did not match any Assets or Stock Parts', 'Match not Found!');
				}
			}));
		});
	}, []);

	useEffect(() => {
		handleResize();
		window.addEventListener("virtualKeyboard", (event) => {
			dispatch(actions_admin.virtualKeyboard(event.detail.keyboard));
		});
	}, []);
	
	useEffect(() => {
		handleResize();
		window.addEventListener("googleIdToken", async (event) => {
			let idToken = event.detail.idToken;
			const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
			await firebase.auth().signInWithCredential(credential);
		});
	}, []);

	useEffect(() => {
		handleResize();
		window.addEventListener("appleIdToken", async (event) => {
			let idToken = event.detail.idToken;
			const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
			await firebase.auth().signInWithCredential(credential);
		});
	}, []);

	useEffect(() => {
		if (window.flutter) {
			window.flutter.callHandler('data', 
				FLUTTER_TABS, 
				FLUTTER_DRAWER_NAV,
				FLUTTER_SETTINGS_NAV,
				users?.user, 
				users?.user_permissions,
				Object.values(ROLES), 
				window.build, 
				settings.settings_global?.app_version,
			);
		}
	}, [users?.user, users?.user_permissions]);
	
	useEffect(() => {
		if (window.flutter) {
			var path = routeLocation.pathname;
			var args = [path, null];

			FLUTTER_NAV.forEach((nav) => {
				if (matchURL(path, nav.path)) args = [path, nav];
			});
			window.flutter.callHandler('navigation', args); 
		}
	}, [routeLocation.pathname]);

	useEffect(() => {
		if (metadata?.buildRevision < settings.settings_global?.build) {
			window.toastr.info('Please click to UPDATE the software to the latest version.', 'UPDATED VERSION AVAILABLE!', {
				timeOut: 0,
				extendedTimeOut: 0,
				onclick: () => { if (window.flutter) window.flutter.callHandler('refresh'); }
			});
		}
	}, [settings.settings_global?.build]);

	useEffect(() => {
		if (window.flutter) window.flutter.callHandler('clockinStatus', users.time_clock?.clockedIn ?? false); 
		// if (window.flutter) window.flutter.callHandler('checkinId', '1000' ?? ''); 
	}, [users.time_clock?.clockedIn]);

	/* Auth State Listener ------------------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
			if (user) {
				dispatch(actions_authentication.validateLogin(params.handle, user, (result) => {

					if (result === 'not_found') history.push('/' + params.handle + '/user_not_found/' + user.email);
					if (result === 'success') {
						dispatch(actions_company.subCompany(params.handle));
						dispatch(actions_profile.subCustomers(params.handle));
						dispatch(actions_profile.subEmployees(params.handle));
						dispatch(actions_profile.subVendors(params.handle));
						dispatch(actions_quickbooks.subQuickbooksSettings(match.params.handle));
						dispatch(actions_service_items.subServiceItemCategories(params.handle));
						dispatch(actions_settings.subAssetClassifications(params.handle));
						dispatch(actions_settings.subAssetTypes(params.handle));
						dispatch(actions_settings.subDepartmentTags(params.handle));
						dispatch(actions_settings.subDepartments(params.handle));
						dispatch(actions_settings.subJobCategories(params.handle));
						dispatch(actions_settings.subPartsCategories(params.handle));
						dispatch(actions_settings.subSettings(params.handle));
						dispatch(actions_users.subTimeClock(params.handle, user.email));
						dispatch(actions_users.subUserPermissions(params.handle, user.email));
						dispatch(actions_users.subUserRecentItems(params.handle, user.email));
						dispatch(actions_users.subUser(params.handle, user.email, (userdata) => {

							if (
								// use window.location.href as routeLocation.pathname may not be updated (except first two)
								routeLocation.pathname == `/${params.handle}` ||
								routeLocation.pathname.includes('/login') ||
								window.location.href.includes('/login') || 
								window.location.href.includes('/register_user') ||
								window.location.href.includes('/register_company')
							) {
								history.push(`/${params.handle}/dashboard`);
							}	
						}));	
					}	
				}));	
			} else {
				console.log('logged out');
				if (
					!window.location.href.includes('/login') &&
					!window.location.href.includes('/register_user') &&
					!window.location.href.includes('/email_link') &&
					!window.location.href.includes('signature')
				) {
					history.push('/' + params.handle + '/login');
				}	
			}	
		});	

		return () => {
			if (typeof unsubscribe === 'function') unsubscribe();
		};	
	}, [params.handle]);	

	/* Handlers ----------------------------------------------------------------------------------------------------------------------*/

	const handleResize = () => {
		setIsSinglePanel(numPanels(1));
	}

	// const toggleMenuCollapsed = () => {
	// 	dispatch(actions_settings.toggleMenuCollapsed());
	// }
		
	const handle = params.handle;
	const P = users?.user_permissions;

	return (

		<Switch>

			<Route exact path={'/' + handle} render={(route_props) =>
				<Redirect to={'/' + handle + '/login'} />
			} />
			<Route path={match.path + '/login/:base64?'} render={(route_props) =>
				<Login landing={'/'} firebase={firebase} params={params} history={history} dispatch={dispatch} />
			} />
			<Route path={match.path + '/logout'} render={(route_props) => 
				dispatch(actions_authentication.logout())
			} />
			<Route path={match.path + '/register_user/:first?/:last?/:email?'} render={(route_props) =>
				<Register landing={'/'} firebase={firebase} history={history} dispatch={dispatch} {...route_props} />
			} />
			<Route path={match.path + '/account_delete'} render={(route_props) =>
			<>
				{ window.flutter &&
					<Navbar handle={handle} />
				}
				<AccountDelete handle={handle} firebase={firebase} params={params} history={history} dispatch={dispatch} />
			</>
			} />
			<Route path={match.path + '/user_not_found/:email'} render={(route_props) =>
				<UserNotFound firebase={firebase} dispatch={dispatch} params={params} />
			} />
			<Route path={match.path + '/password_reset'} render={(route_props) =>
				<PasswordReset firebase={firebase} params={params} history={history} dispatch={dispatch} />
			} />
			<Route path={match.path + '/quickbooks'} render={(route_props) =>
				<QuickbooksConnect firebase={firebase} {...route_props} />
			} />
			<Route path={match.path + '/signature/:item/:id/:validateSignature'} render={(route_props) =>
				<Signature firebase={firebase} {...route_props} />
			} />
			<Route path={match.path} render={(route_props) =>
				<>
					{ !isSinglePanel &&
						<SideMenu
							handle={handle}
							location={routeLocation}
							user={users?.user}
							userRoles={Object.values(ROLES)}
							collapsed={settings.collapsed}
						/>
					}
					<Navbar handle={handle} />

					<div id="wrapper" style={{ height: (window.flutter) ? 'calc(100vh)' : 'calc(100vh - 53px)' }}>

						<div id="page-wrapper" className="gray-bg px-0" style={{ marginLeft: (isSinglePanel) ? '0' : '79px' }}>

							<div className="container-fluid">
								{!company.company || !users.users // only check for these two.  Other tables, check on individual pages.
									? < div style={{ position: 'absolute', top: '50%', left: '50%' }}>
										<Spinner />
									</div>
									: <>
										<Route path={match.path + '/dashboard'} render={(route_props) =>
											<div className="row">
												<div className='col-12'>
													<CatchErrors className={'m-t-lg'}>
														<Dashboard {...route_props} />
													</CatchErrors>
												</div>
											</div>
										} />
										{P?.CUSTOMERS_VIEW &&
											<Route path={match.path + '/customers'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Customers {...route_props} />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										{P?.ASSETS_VIEW &&
											<Route path={match.path + '/assets'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Assets {...route_props} />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										{P?.STOCK_PARTS_VIEW &&
											<Route path={match.path + '/stock_parts'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<StockParts {...route_props} />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										{P?.VENDORS_VIEW &&
											<Route path={match.path + '/vendors'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Vendors {...route_props} />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										{P?.SCHEDULE_VIEW &&
											<Route path={match.path + '/schedule'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Schedule {...route_props} />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										{P?.EMPLOYEES_VIEW &&
											<Route path={match.path + '/employees'} render={(route_props) =>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Employees {...route_props} app="maintenance" />
													</CatchErrors>
												</React.Suspense>
											} />
										}
										<Route path={match.path + '/archive'} render={(route_props) =>
											<div className="row">
												<CatchErrors className={'m-t-lg'}>
													<Archive {...route_props} />
												</CatchErrors>
											</div>
										} />
										<Route path={match.path + '/settings'} render={(route_props) =>
											<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
												<CatchErrors className={'m-t-lg'}>
													<Settings {...route_props} />
												</CatchErrors>
											</React.Suspense>
										} />
									</>
								}
								{ !numPanels(1) &&
									<Footer />
								}
							</div>
						</div>
					</div>
				</>
			} />
		</Switch>
	);
};
