
import * as types from 'actions/action-types';

var initialState = {
    customers: [],
    customers_pending: false,
    customers_save_pending: false,
    customers_unsubscribe: false,
};

const CustomersReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.CUSTOMERS + '_PENDING': pending('customers'); break;
        case types.CUSTOMERS + '_SAVE_PENDING': save_pending('customers'); break;
        case types.CUSTOMERS + '_SAVE_FULFILLED': save_fulfilled('customers'); break;
        case types.CUSTOMERS + '_FULFILLED': fulfilled('customers'); break;
        case types.CUSTOMERS + '_CLEAR': clear('customers', {}); break;

        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_saved']: action.data,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_save_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        }
    }
}
export default CustomersReducer;


