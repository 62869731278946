import * as actions_profiles from 'actions/profiles-actions';
import React, { useState } from 'react';
import { Select } from 'enspire-manager-framework';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DASHBOARDS, DEFAULT_DASHBOARDS } from 'components/common/constants';

export default function DashboardSelect(props) {

	/* Hooks --------------------------*/

	const params = useParams();
    const dispatch = useDispatch();

    const user = useSelector((store) => store.users.user);

	const [isEditMode, setEditMode] = useState(false);

	const editPanel = () => {
		setEditMode(!isEditMode);
	}
	const handleFilter = (e) => {
		let newDashboards = [ ...(user.contact?.dashboards) ? user.contact?.dashboards : DEFAULT_DASHBOARDS ];
		newDashboards[props.index] = e.target.value;
		setEditMode(false);

		dispatch(actions_profiles.saveContact(params.handle, user.profileId, { dashboards: newDashboards, id: user?.contact?.id, email: user.email }, 'employee', () => {
			window.toastr.success('The User Preferences have been successfully saved/updated', 'User Preferences Saved!');
		}));
	
	};

	const dashboard = Object.values(DASHBOARDS).find((dash) => dash.id == params['dashboard' + (props.index + 1)]);

	return (
		<>
			{ !isEditMode
			?	<h1 className="px-3" role="button" onClick={ editPanel }>{ dashboard?.name }<i className="fas fa-angle-down text-muted float-right"></i></h1>
			:	<Select
					className="mt-2 mx-3"
					autoFocus={ true }
					noLabel={ true }
					name={'dashboard'}
					value={params['dashboard' + (props.index + 1)]}
					onChange={handleFilter}
					onBlur={() => setEditMode(false)}
				>
					<option key={`technicians`} value={'technician'}>My Work Orders</option>
					<option key={`service_requests`} value={'service_requests'}>Open Service Requests</option>
					<option key={`assets`} value={'assets'}>Asset Maintenance Costs</option>
					<option key={`projects`} value={'projects'}>Projects</option>
					
				</Select>
			}
		</>
	);
}
