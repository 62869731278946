import React from 'react';
import { Spinner } from 'enspire-manager-framework';

const QuickbooksConnect = (props) => {

    React.useEffect(() => {

        setTimeout(() => {
            var  currentUser = props.firebase.auth().currentUser;
            if ( currentUser  ) currentUser.getIdToken().then((token) => {
                console.log(token);
                window.location = "https://us-central1-mobile-track-systems.cloudfunctions.net/authenticateQuickbooks/requestToken/" + props.match.params.handle + "/" + token;
            });
        }, 500);

    }, []);


    return (
        <div id="wrapper">
            <h2 style={{ marginTop: '200px', textAlign: 'center', color: '#888888' }}>Quickbooks Connect...</h2>
        </div>
    );
};

export default QuickbooksConnect;
