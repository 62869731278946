import * as actions_service_requests from 'actions/service-requests-actions';
import * as actions_settings from 'actions/settings-actions';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Avatar, Ibox, Table } from 'enspire-manager-framework';
import { REQUESTS } from 'components/common/constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

var moment = require('moment');

const OpenWorkOrders = (props) => {

    /* Hooks --------------------------*/

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const settings = useSelector((store) => store.settings);
	const services = useSelector((store) => store.services);
	const serviceRequests = useSelector((store) => store.services.service_requests);

    const [data, setData] = useState([]);

    /* UseEffect --------------------------*/

    useEffect(() => {
        dispatch(actions_service_requests.subOpenServiceRequests(params.handle));
        
        return () => {
			const unsubscribe = services.service_requests_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle]);

    useEffect(() => {
        var service_requests = [];
        services?.service_requests?.forEach((serviceRequest) => {
            let status = _.find(Object.values(REQUESTS), { id: serviceRequest.statusId });
            service_requests.push({
                ...serviceRequest,
                status: status?.name,
                image: <Avatar className="mx-auto"
                    color="white"
                    bgColor="saddleBrown"
                    size={60}
                    fontSize={24}
                    name={serviceRequest.name}
                    image={serviceRequest.imageUrl}
                    imageWidth={60}
                    border="4px solid white"
                    role={ (serviceRequest.imageUrl) ? "button" : null }
                />,
                _stripe_color: status?.color,
            });
        });
        setData(service_requests);
    }, [services?.service_requests]);


    /* Actions ----------------------------*/

    const openServiceRequest = (serviceRequest) => {
        actions_service_requests.serviceRequestURL(params.handle, serviceRequest, (locations) => {
            if (settings.dualMonitor2) {
                dispatch(actions_settings.updateSettings(params.handle, {
                    'dualMonitor1Url': `${locations[0]}|${locations[1]}}`
                }));
            } else {
                history.push({ pathname: locations[0] });
                history.push({ pathname: locations[1] });
            }
        });
    }

    /* Constants --------------------------*/
    
    const columns = [
        { name: "UNIT #", field: "unitNumber", nowrap: true, width: 15 },
        { name: 'Asset', field: '_name', width: 50 },
        { name: 'Requested', field: 'requestDate', type: 'date', format: 'MMM Do, YYYY', nowrap: true, width: 25 },
        { name: 'Summary', field: 'description' },
    ];

    const title = "Open Service Requests";

    return (
        <Ibox className="mt-3 mb-0" title={title} show_spinner={serviceRequests.work_orders_pending} no_fade={true}>
            <Table
                data={data}
                table_style={{ minWidth: '400px', overflowX: 'scroll' }}
                columns={columns}
                active_field={'id'}
                active_id={params.work_order_id}
                second_line={'description'}
                image={'image'}
                group_by={{ fields: ['statusId'], direction: ['asc'], display: ['status'] }}
                click_callback={openServiceRequest}
                show_search={true}
                chevron={true}
                highlight_search={true}
                limit={10}
            >
            </Table>
        </Ibox>
    );
};

export default OpenWorkOrders;