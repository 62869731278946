import React from 'react';
import { Avatar } from 'enspire-manager-framework';
import { useSelector } from 'react-redux';

const ChatConversation = (props) => {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const employees = useSelector((store) => store.employees.employees);
    const unreadMessages = useSelector((store) => store.chat.unread_messages)?.filter((message) => message.conversationId == conversation.id);

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const user = props.user;
    const conversation = props.conversation;

    var users = {};
    employees.forEach((employee) => {
        if (employee.contact?.email) users[employee.contact.email] = { ...employee };
    });

    const userCount = conversation.userIds.length;
    const userList = conversation?.userIds.map((userId) => users[userId]?.firstName + ' ' + users[userId]?.lastName).join(", ");

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <li className={`clearfix ${(props.selected) && "active"} d-flex py-3 align-items-center`} onClick={() => props.onClick(conversation.id)}>

            {
                (conversation.userIds.length == 1) ?
                    <>
                        <Avatar
                            color="white"
                            bgColor="grey"
                            size={50}
                            name={`${user?.firstName} ${user?.lastName}`}
                            image={user?.photoUrl}
                            fontSize={20}
                        />
                        <div className="about">
                            <div className="name">{user?.firstName + ' ' + user?.lastName}<span className="badge badge-danger ml-3">{(unreadMessages?.length > 0) && unreadMessages?.length}</span></div>
                            <div className="status"> <i className={`fa fa-circle online `}></i>INSERT STATUS HERE</div>
                        </div>
                    </>
                    : <div data-toggle={(conversation.userIds.length > 2) && 'tooltip'} title={(conversation.userIds.length > 2) && userList}>
                        <div className={`avatars ${(userCount >= 4) ? "offSet-4" : (userCount == 3) ? 'offSet-3' : 'offSet-2'} justify-content-end`} >
                            {conversation.userIds.map((userId, index) => {

                                if (index < 3 || index == 3 && conversation.userIds.length == 4) {
                                    return <Avatar
                                        className={'avatar'}
                                        color="white"
                                        bgColor="grey"
                                        size={50}
                                        name={`${users[userId]?.firstName} ${users[userId]?.lastName}`}
                                        image={users[userId]?.photoUrl}
                                        fontSize={20}
                                    />;
                                } else if (index == 3) {
                                    return <Avatar
                                        className={'avatar'}
                                        color="white"
                                        bgColor="grey"
                                        size={50}
                                        name={`+ ${conversation.userIds.length - 3}`}
                                        fontSize={20}
                                    />;
                                }
                            })}
                        </div>
                        <div className={`d-flex ${(conversation.userIds.length >= 4) ? "groupOffSet-4" : (conversation.userIds.length == 3) ? 'groupOffSet-3' : 'groupOffSet-2'}`}>
                            <div>
                                <div className="name d-flex align-items-center">{(conversation.userIds.length == 2)
                                    ? users[conversation.userIds?.[0]]?.firstName + ' ' + users[conversation.userIds?.[0]]?.lastName + ' & ' + users[conversation.userIds?.[1]]?.firstName + ' ' + users[conversation.userIds?.[1]]?.lastName
                                    : users[conversation.userIds?.[0]]?.firstName + ' ' + users[conversation.userIds?.[0]]?.lastName + ' & ' + (conversation.userIds.length - 1) + ' others'
                                } &nbsp;<span className="badge badge-danger">{(unreadMessages?.length > 0) && unreadMessages?.length}</span></div>
                                <div className="status"> <i className={`fa fa-circle online `}></i>INSERT STATUS HERE</div>
                            </div>
                        </div>
                    </div>
            }

        </li>
    );
};

export default ChatConversation;