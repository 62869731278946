import * as actions_users from 'actions/users-actions';
import React, { useEffect, useState } from 'react';
import { Avatar } from 'enspire-manager-framework';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

export default function TimeClock(props) {

    const dispatch = useDispatch();

    const users = useSelector((store) => store.users);
    const user = users.user;
    const name = user?.firstName + ' ' + user?.lastName;

    /* Hooks --------------------------*/

	const params = useParams();
    const [accumulated, setSeconds] = useState(0);
    const [currentTime, setCurrentTime] = useState();

    /* Effects --------------------------*/

    useEffect(() => {
        const interval = setInterval(() => {
            var seconds = users.time_clock?.secondsAccumulated ?? 0;
            if (users.time_clock?.clockedIn) {
                var newSeconds = moment().diff(moment(users.time_clock?.lastTimeIn.toDate()), 'seconds');
                setSeconds(seconds + newSeconds);
            } else {
                setSeconds(seconds);
            }
            setCurrentTime(moment());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [users.time_clock?.secondsAccumulated, users.time_clock?.clockedIn]);

    /* Actions --------------------------*/
	
	const handleClockIn = () => {
		dispatch(actions_users.clockIn(params.handle, user.id));
	};
	const handleClockOut = () => {
		dispatch(actions_users.clockOut(params.handle, user.id));
	};
    const timeTotal = moment.utc((accumulated + 1) * 1000);

    return (

        <div className="row mt-3 mb-4 px-3 px-md-5">
            <div className="col-12 pt-3" style={{ backgroundColor: '#dddddd', borderRadius: '12px' }}>
                <div className="row">
                    <div className="col-3">
                        <Avatar className="ml-2" bgColor="darkOrange" color="white" size={70} fontSize={30} name={name} image={user.photoUrl} border={'2px solid white'} />
                    </div>
                    <div className="col-5">
                        <p className="m-0 nowrap" style={{ fontSize: '24px' }}>
                            <i className="far fa-clock mr-2 d-none d-sm-inline"></i>
                            {currentTime?.format('hh:mm:ss') ?? '00:00:00'}
                            <span style={{ fontSize: '16px' }}>{currentTime?.format('a')}</span>
                        </p>
                        <p className="mb-3 mt-n2 nowrap" style={{ fontSize: '16px' }}>
                            Today:<span className="ml-2" style={{ fontSize: '22px' }}>{ `${timeTotal?.format('H')}h ${timeTotal?.format('mm')}m` }</span>
                        </p>
                        <p className="mb-3 mt-n2 nowrap text-muted" style={{ fontSize: '12px' }}>
                            { users.time_clock?.clockedIn && <>Clocked In: { moment(users.time_clock?.lastTimeIn?.toDate()).format('MMM D [at] h:mm A') }</>}
                            { !users.time_clock?.clockedIn && <>Clocked Out: { moment(users.time_clock?.lastTimeOut?.toDate()).format('MMM D [at] h:mm A') }</>}
                        </p>
                    </div>
                    <div className="col-4">
                        <div className="mr-3">
                            <button className={ `btn btn-${(users.time_clock?.clockedIn)?'default':'success'} btn-sm btn-block` } 
                                disabled={users.time_clock?.clockedIn} onClick={handleClockIn}>Clock&nbsp;In</button>
                            <button className={ `btn btn-${(users.time_clock?.clockedIn)?'danger':'default'} btn-sm btn-block` } 
                                disabled={!users.time_clock?.clockedIn} onClick={handleClockOut}>Clock&nbsp;Out</button>
                            {/* <button className={ `btn btn-info btn-sm btn-block` } 
                                disabled={!users.time_clock?.clockedIn} onClick={handleClockOut}><i class="far fa-clock"></i> Time Card</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

