import React, { useState } from 'react';
import { Avatar } from 'enspire-manager-framework';
import { Spinner } from 'enspire-manager-framework';
import { sanitize } from 'components/common/toolbox';

export function RegisterCompany(props) {

    const fields = ['company_name', 'first_name', 'last_name', 'email', 'mobile', 'password', 'verify_password']
	const [checked, setChecked] = useState(false);
    const [state, setState] = useState({
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        password: '',
        verify_password: '',

        passwords_mismatch: false,
        loading: false,
        authorizing: false,
        handle: props.handle,
    });

    const handleChange = (event) => {
        if (event.target.name == 'email') event.target.value = event.target.value.toLowerCase();
        setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
        document.getElementById(event.target.name).style = "";

        if (event.target.name == 'password' || event.target.name == 'verify_password') {
            setState(prev => ({ ...prev, passwords_mismatch: false }));
        }
    };
    const submitForm = async (event) => {
        event.preventDefault();

        var missingRequired = false;
        fields.forEach(field => {
            if (state[field] === '') {
                missingRequired = true;
                document.getElementById(field).style = "border: 1px solid red";
            }
        });
        if (missingRequired) {
            window.toastr.error('Missing required fields');
            return;
        }

        if (state.password !== state.verify_password || state.password === '' || state.verify_password === '') {
            setState(prev => ({ ...prev, passwords_mismatch: true }));
            document.getElementById('password').style = "border: 1px solid red";
            document.getElementById('verify-password').style = "border: 1px solid red";
            window.toastr.error('Passwords do not match');
        }


        const data = {
            company_name: state.company_name,
            first_name: state.first_name,
            last_name: state.last_name,
            email: state.email,
            mobile: state.mobile,
            password: state.password,
            handle: state.handle,
        }
        setState(prev => ({ ...prev, authorizing: true }));
        // Registration callback verifies user registration and then calls cloud function to create company
        await props.registrationCallback(sanitize(data));
        setState(prev => ({ ...prev, authorizing: false }));
    };

    var passwordStyle = (state.passwords_mismatch) ? { border: '1px solid red' } : {};

    return (

        <div id="wrapper" className="gray-bg" style={{ paddingBottom: '300px' }}>

            <div className="middle-box text-center loginscreen animated fadeInDown">
                <div>
                    <div style={{ margin: '20px 0' }}>
                        <Avatar
                            className="mx-auto"
                            color="white"
                            bgColor="saddleBrown"
                            size={250}
                            name={state.handle}
                            initials={false}
                            border="8px solid white"
                        />
                    </div>


                    <form className="register-form" style={{ marginTop: '40px' }} onSubmit={submitForm.bind(this)}>
                        {/* <div className="form-group text-start">
                            <h2>ID: <strong>{state.handle}</strong> </h2>
                        </div> */}
                        <div className="form-group">
                            <input type="text" name="company_name" id='company_name' className="form-control" placeholder="Company Name" value={state.company_name} onChange={handleChange.bind(this)} />
                        </div>
                        <div className="form-group text-start">
                            <h2>Adminstrator</h2>
                        </div>
                        <div className="form-group">
                            <input type="text" name="first_name" id='first_name' className="form-control" placeholder="First Name" value={state.first_name} onChange={handleChange.bind(this)} />
                        </div>
                        <div className="form-group">
                            <input type="text" name="last_name" id='last_name' className="form-control" placeholder="Last Name" value={state.last_name} onChange={handleChange.bind(this)} />
                        </div>
                        <div className="form-group">
                            <input type="text" name="email" id='email' className="form-control" placeholder="Email Address" inputMode="email" value={state.email} onChange={handleChange.bind(this)} />
                        </div>
                        <div className="form-group">
                            <input type="text" name="mobile" id='mobile' className="form-control" placeholder="Mobile Number" inputMode="tel" value={state.mobile} onChange={handleChange.bind(this)} />
                        </div>
                        <div className="form-group">
                            <input type="password" id="password" name="password" className="form-control" placeholder="Password" style={passwordStyle} value={state.password} onChange={handleChange.bind(this)} autoComplete="off" />
                        </div>
                        <div className="form-group">
                            <input type="password" id="verify_password" name="verify_password" className="form-control" placeholder="Verify Password" style={passwordStyle} value={state.verify_password} onChange={handleChange.bind(this)} autoComplete="off" />
                        </div>
                        <div className="form-check my-4">
                            <input className="form-check-input my-auto" type="checkbox" checked={checked} id="flexCheckDefault" onChange={() => setChecked(!checked)} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                I agree to the Terms of Use and Privacy Policy
                            </label>
                        </div>

                        {!state.authorizing
                            ? <button type="submit" disabled={(!checked) ? true : false} className="btn btn-primary block full-width m-b mt-4">REGISTER NEW COMPANY</button>
                            : <div style={{ margin: '15px 0' }}><Spinner /></div>
                        }
                        <div className="mt-5">
                            Fine print about 14-day trial for a single user, blah blah blah...
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};