import * as toolbox from '../components/common/toolbox';
import * as actions_admin from './admin-actions';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { onSnapshot } from "firebase/firestore";

const firestore = firebase.firestore();
const _ = require('lodash');

export function subConversations(handle, userId) {

    return async dispatch => {

        dispatch({ type: types.CONVERSATIONS + '_PENDING' });

        var query = firestore.collection(handle + '/private-messages/conversations').where('userIds', 'array-contains', userId);

        const unsubscribe = onSnapshot(query, (querySnapshot) => {
            var conversations = [];
            querySnapshot.forEach((doc) => { conversations.push({ ...doc.data(), id: doc.id }); });
            dispatch({ type: types.CONVERSATIONS + '_FULFILLED', data: conversations, unsubscribe });
        });

    };
}
export function addConversation(handle, users, callback) {
    return dispatch => {

        dispatch({ type: types.CONVERSATIONS + '_SAVE_PENDING' });

        const newConversation = { lastDate: new Date, userIds: users };
        firestore.collection(handle + '/private-messages/conversations').add(newConversation).then((doc) => {
            dispatch({ type: types.CONVERSATIONS + '_SAVE_FULFILLED' });
            if (typeof callback === 'function') callback({ ...newConversation, id: doc.id });
        }).catch((error) => {
            toolbox.process_error(error, 'Conversation NOT Created!');
        });
    };
}
export function updateConversation(handle, users, conversationId, callback) {
    return dispatch => {

        dispatch({ type: types.CONVERSATIONS + '_SAVE_PENDING' });

        firestore.collection(handle + '/private-messages/conversations').doc(conversationId).update(users).then(() => {
            dispatch({ type: types.CONVERSATIONS + '_SAVE_FULFILLED' });
            if (typeof callback === 'function') callback();
        }).catch((error) => {
            toolbox.process_error(error, 'Conversation NOT Created!');
        });
    };
}
export function subMessages(handle, conversationId) {

    return async dispatch => {

        dispatch({ type: types.MESSAGES + '_PENDING' });

        var query = firestore.collection(handle + '/private-messages/conversations/' + conversationId + '/messages').orderBy('date', 'desc').limit(50);

        const unsubscribe = onSnapshot(query, (querySnapshot) => {
            var messages = [];
            querySnapshot.forEach((doc) => { messages.push({ ...doc.data(), id: doc.id, date: doc.data().date.toDate() }); });

            dispatch({ type: types.MESSAGES + '_FULFILLED', data: messages, unsubscribe });
        });

    };
}
export function subUnreadMessages(handle, userId) {

    return async dispatch => {

        dispatch({ type: types.UNREAD_MESSAGES + '_PENDING' });

        var query = firestore.collectionGroup('messages').where("unreadBy", 'array-contains', userId).where('handle', '==', handle);

        const unsubscribe = onSnapshot(query, (querySnapshot) => {
            var messages = [];
            querySnapshot.forEach((doc) => {
                if (doc._key.path.segments[5] == handle) messages.push({ ...doc.data(), id: doc.id, date: doc.data().date.toDate() });
            });
            dispatch({ type: types.UNREAD_MESSAGES + '_FULFILLED', data: messages, unsubscribe });
        });

    };
}
export function sendMessage(handle, conversationId, message, sentBy, userList, callback) {
    return dispatch => {

        dispatch({ type: types.MESSAGES + '_SAVE_PENDING' });

        var batch = firestore.batch();

        batch.set(firestore.collection(handle + '/private-messages/conversations').doc(conversationId), { lastDate: new Date }, { merge: true });
        batch.set(firestore.collection(handle + '/private-messages/conversations/' + conversationId + '/messages').doc(), { ...message, handle: handle });
        batch.commit().then(() => {
            dispatch({ type: types.MESSAGES + '_SAVE_FULFILLED' });
            // dispatch(actions_admin.saveActivity(handle, `Sent Message to ${userList}`, null, ACTIVITY_LOG.MESSAGE_SENT.id, sentBy));
            if (typeof callback === 'function') callback();
        }).catch((error) => {
            toolbox.process_error(error, 'Message NOT Sent!');
        });
    };
}
export function readMessages(handle, messages, conversationId) {
    return dispatch => {

        dispatch({ type: types.MESSAGES + '_SAVE_PENDING' });

        var batch = firestore.batch();

        messages.forEach((message) => {
            const id = message.id;
            delete message.id;
            batch.update(firestore.collection(handle + '/private-messages/conversations/' + conversationId + '/messages').doc(id), message);
        });

        batch.commit().then(() => {
            dispatch({ type: types.MESSAGES + '_SAVE_FULFILLED' });
        }).catch((error) => {
            toolbox.process_error(error, 'Conversation NOT Created!');
        });
    };
}
