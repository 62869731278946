import React from 'react';
import { Avatar } from 'enspire-manager-framework';

const UserList = (props) => {
    const user = props.user;

    return (
        <div className={`user-select d-flex py-2 px-3`} onClick={() => props.onClick(user)}>

            <Avatar
                className={'mr-2'}
                color="white"
                bgColor="darkOrange"
                size={50}
                name={`${user.firstName} ${user.lastName}`}
                image={user.photoUrl}
                fontSize={25}
            />
            <div className="about my-auto">
                <div className="name">{user?.firstName + ' ' + user.lastName}</div>
                <div className="status"> <i className={`fa fa-circle online `}></i>INSERT STATUS HERE</div>
            </div>
        </div>
    );
};

export default UserList;