import React, { useState } from 'react';
import SignaturePad from 'signature_pad';
import firebase from 'firebase/compat/app';
import { ModalAlert } from 'enspire-manager-framework';
import { UCFirst } from 'components/common/toolbox';
import { useParams } from 'react-router-dom';
import { useDispatch} from 'react-redux'

const storage = firebase.storage();
const storageRef = storage.ref();

export default function Signature(props) {
    
    const dispatch = useDispatch();
    const [ signaturePad, setSignaturePad ] = useState(null);
    const { handle, item, id, validateSignature } = useParams();
    const [ saving, setSaving ] = useState(false);
    const [ saved, setSaved ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const [ declined, setDeclined ] = useState(false);

    React.useEffect(() => {
        
        var unsubscribe;
        // if (item == 'quote') dispatch(actions_quotes.subQuoteById(handle, id, (quote, unsub) => {
        //     if (quote.signatureUrl) setSaved(true);
        //     unsubscribe = unsub;
        // }));
        
        return () => unsubscribe();
    }, []);

    React.useEffect(() => {
        
        const canvas = window.document.querySelector("canvas");
        const signaturePad = new SignaturePad(canvas);
        setSignaturePad(signaturePad);
        window.onresize = resizeCanvas;
        resizeCanvas();
        
        function resizeCanvas() {
            const ratio =  Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);
            signaturePad.clear();
        }

        return () => window.onresize = null;
    }, []);

    const handleSave = () => {
        var dataUrl = signaturePad.toDataURL();

		var ref = storageRef.child(handle + '/dispatch_attachments/signatures/' + item + '/' + id);
		var uploadTask = ref.putString(dataUrl, 'data_url');

        setSaving(true);

        uploadTask.on('state_changed', (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress);

        }, (error) => {
            console.error(error);
            
        }, async () => {

            let signatureUrl = await uploadTask.snapshot.ref.getDownloadURL();

            try {

                if (item == 'quote') {
                    var result = await firebase.functions().httpsCallable('customerSignsQuote')({ 
                        handle: handle, 
                        quoteId: id,
                        signatureUrl,
                        validateSignature,
                    });

                    if (result.data.result == 'error') {
                        setErrorMessage(result.data.message);
                    }
                }

            } catch(err) {
                console.log(err);
            }

            setTimeout(() => {
                setSaving(false);
                setSaved(true);
            }, 500);
        });		
    }

    const handleClear = () => { signaturePad.clear(); }
    
    const handleDecline = () => {
        ModalAlert({
            title: 'Are you sure?',
            text: "Declining the Quote will halt all further progress on your service",
            icon: 'warning',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, Decline Quote',
            callback_success: () => {
                // if (item == 'quote') dispatch(actions_quotes.saveQuote(handle, { id: id, statusId: '3' }));
                // setDeclined(true);
            }
        });

    }

    return (
        <div id="signature-body">

            <div id="signature-pad" className="signature-pad">
                <div className="signature-pad--body">
                    <canvas></canvas>
                </div>
                <div className="signature-pad--footer">
                    <div className="description mb-2">Sign above</div>

                    <div style={{ color: '#666666' }}>
                        { errorMessage
                            ?   <h2 style={{ color: 'red' }}>{ errorMessage }</h2>
                            :   <>
                                    { declined
                                        ?   <h2 style={{ textAlign: 'center' }}>Thank You!  Please call our office if there's anything else we can do to serve you!</h2>
                                        :   <>
                                                { saved
                                                    ?   <h2 style={{ textAlign: 'center' }}>Signature Saved!  Thank You!</h2>
                                                    :   <p style={{ fontSize: '16px' }}>I have read <strong>{ UCFirst(item) } #{ id }</strong> and agree to the terms set forth within:</p>
                                                }
                                            </>
                                    }
                                </>
                        }
                    </div>

                    { !saved &&
                        <div className="row">
                            <div className="col-3">
                                <button className="btn btn-danger btn-sm mt-3" onClick={ handleDecline }>Declined</button>
                            </div>
                            <div className="col-9">
                                <button className="btn btn-success btn-sm float-right mt-3" onClick={ handleSave }>
                                    { saving &&
                                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                    }
                                    Apply Signature & Accept Terms

                                </button>
                                <button className="btn btn-default btn-sm float-right mt-3 mr-3" onClick={ handleClear }>Clear Signature Pad</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
