
import * as types from 'actions/action-types';

var initialState = {
    asset: null,
    asset_pending: false,
    asset_save_pending: false,
    asset_fulfilled: false,
    asset_unsubscribe: false,

    asset_attachments: [],
    asset_attachments_pending: false,
    asset_attachments_save_pending: false,
    asset_attachments_fulfilled: false,
    asset_attachments_unsubscribe: false,
    
    assets: [],
    assets_pending: false,
    assets_fulfilled: false,
    assets_unsubscribe: false,
    
    asset_type: null,
    customer: null,
};

const AssetReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.ASSET + '_PENDING': pending('asset'); break;
        case types.ASSET + '_SAVE_PENDING': save_pending('asset'); break;
        case types.ASSET + '_FULFILLED': fulfilled('asset'); break;
        case types.ASSET + '_SAVE_FULFILLED': save_fulfilled('asset'); break;

        case types.ASSET + '_' + types.ATTACHMENTS + '_PENDING': pending('asset_attachments'); break;
        case types.ASSET + '_' + types.ATTACHMENTS + '_SAVE_PENDING': save_pending('asset_attachments'); break;
        case types.ASSET + '_' + types.ATTACHMENTS + '_FULFILLED': fulfilled('asset_attachments'); break;
        case types.ASSET + '_' + types.ATTACHMENTS + '_SAVE_FULFILLED': save_fulfilled('asset_attachments'); break;
        case types.ASSET + '_' + types.ATTACHMENTS + '_CLEAR': clear('asset_attachments', []); break;

        case types.ASSETS + '_PENDING': pending('assets'); break;
        case types.ASSETS + '_FULFILLED': fulfilled('assets'); break;
        case types.ASSETS + '_CLEAR': clear('assets', {}); break;

        case types.ASSET_TYPE + '_SELECTED': selected('asset_type'); break;
        case types.CUSTOMER + '_SELECTED': selected('customer'); break;

        default: break;
    }

    return state;

    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_saved']: action.data,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function selected(table) {
        state = {
            ...state,
            [table]: action.data,
        };
    }
    function clear(table, value) {
        state = {
            ...state,
            [table]: value,
        };
    }
};
export default AssetReducer;


