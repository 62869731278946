export const APPS = {
    SERVICE: { id: '1', name: 'Service Manager', short: 'Service' },
    ASSETS: { id: '2', name: 'Asset Manager', short: 'Assets' },
    LOGISTICS: { id: '3', name: 'Logistics', short: 'Logistics' },
};
export const ADDRESSES = {
    BILLING: { id: '1', name: 'Billing', source: ['customers', 'vendors'] },
    SERVICE: { id: '2', name: 'Service', source: ['customers'] },
    DELIVERY: { id: '3', name: 'Delivery', source: ['customers', 'vendors'] },
    PHYSICAL: { id: '4', name: 'Physical', source: ['customers', 'employees'] },
    MAILING: { id: '5', name: 'Mailing', source: ['customers', 'employees'] },
};
export const CONTACTS = {
    BILLING: { id: '1', name: 'Billing' },
    CONTACT: { id: '2', name: 'Contact' },
    MANAGER: { id: '3', name: 'Manager' },
    OWNER: { id: '4', name: 'Owner' },
};
export const DASHBOARDS = {
    TECHNICIAN: { id: 'technician', name: 'My Work Orders' },
    SERVICE_REQUESTS: { id: 'service_requests', name: 'Open Service Requests' },
    ASSETS: { id: 'assets', name: 'Assets Maintenance Costs' },
    PROJECTS: { id: 'projects', name: 'Projects' },
};
export const INVOICES = {
    DRAFT: { id: '1', name: 'Draft', color: '#cccccc', description: 'This invoice in in Draft status and has not been presented to a customer for payment.  A Draft Invoice may be deleted.' },
    SENT: { id: '2', name: 'Sent', color: '#e8e475', description: 'This invoice has been Sent to the Customer and is ready for payment.' },
    PAID: { id: '3', name: 'Paid', color: '#68bc00', description: 'Paid indicates that the invoice has been received and paid in full' },
    PARTIAL: { id: '4', name: 'Partial', color: '#a4dd00', description: 'There has been Partial payment made on this invoice.' },
    OVERDUE: { id: '5', name: 'Overdue', color: 'orange', description: 'The invoice has been sent to the Customer and has passed the due date.' },
    REJECTED: { id: '6', name: 'Rejected', color: 'red', description: 'The Customer has permanently Rejected the invoice.' },
    VOID: { id: '7', name: 'Void', color: '#679cca', description: 'A Voided invoice is considered closed or uncollectable' },
    CLOSED: { id: '8', name: 'Closed', color: 'black', description: 'The Invoice is completed and applied to internal use.' },
};
export const PROFILES = {
    vendor: {
        singular: 'Vendor',
        plural: 'Vendors',
        index: 'vendor',
    },
};
export const PURCHASE_ORDERS = {
    AUTO: { id: '0', name: 'Auto', color: '#eeeeee', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
    DRAFT: { id: '1', name: 'Draft', color: '#cccccc', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
    OPEN: { id: '2', name: 'Open', color: '#16a5a5', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
    RECEIVED: { id: '3', name: 'Received', color: '#a4dd00', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
    PAID: { id: '4', name: 'Paid', color: '#68bc00', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
};
export const QUOTE = {
    DRAFT: '1',
    PENDING: '2',
    REJECTED: '3',
    ACCEPTED: '4',
    CONVERTED: '5',
    CLOSED: '6',
};
export const REQUESTS = {
    UNASSIGNED: { id: '0', name: 'Unassigned', color: '#b3b3b3', description: 'One or more Work Order associated with this Service Request has not yet been assigned to a technician. This can be done by editing the Work Order, or dragging it to Technician\'s Schedule from the Schedule view.' },
    ASSIGNED: { id: '1', name: 'Assigned', color: '#16a5a5', description: 'All the Work Orders associated with this Service Request have been assigned to a technician.' },
    INCOMPLETE: { id: '2', name: 'Incomplete', color: '#e8e475', description: 'One or more Work Order associated with this Service Request has been marked as "Incomplete" for a specified reason, usually requiring followup by the Office or another Technician.' },
    COMPLETE: { id: '3', name: 'Complete', color: '#a4dd00', description: 'All the Work Orders for this Service Request have been marked as "Complete" by the Technician.' },
    INVOICED: { id: '4', name: 'Invoiced', color: '#68bc00', description: 'The Customer has been invoiced for all Work Orders associated with this Service Request.' },
    CLOSED: { id: '5', name: 'Closed', color: '#333333', description: 'This Work Orders associated with this Service Request have been Completed, Invoiced, and Paid, or Incomplete and Closed due to Customer Request.  There are no pending actions.' },
};
export const ROLES = {
    OWNER: { id: '99', name: 'Owner', order: 6 },
    ADMINISTRATOR: { id: '0', name: 'Administrator', order: 5 },
    DISPATCHER: { id: '1', name: 'Dispatcher', order: 1 },
    TECHNICIAN: { id: '2', name: 'Technician', order: 2 },
    ESTIMATOR: { id: '3', name: 'Estimator', order: 3 },
    INVENTORY: { id: '4', name: 'Inventory Manager', order: 5 },
    ACCOUNTING: { id: '5', name: 'Accounting', order: 5 },
};
export const SCHEDULE_EVENT_TYPE = {
    RESERVED_TIMELINE: { id: '0', name: 'Reserved Timeline' },
    WORKORDER: { id: '1', name: 'Work Order' },
    REQUESTED_TIME_OFF: { id: '2', name: 'Requested Time Off' },
    APPROVED_TIME_OFF: { id: '3', name: 'Approved Time Off' },
    INTERNAL_WORKORDER: { id: '4', name: 'Internal Work Order' },
};
export const SERVICE_ITEMS = {
    LABOR: { id: 'labor', position: 1, name: 'Labor', type: 'Service' },
    STOCK_PARTS: { id: 'stock', position: 2, name: 'Stock Parts', type: 'Inventory' },
    NON_STOCK_PARTS: { id: 'non-stock', position: 3, name: 'Non-Stock Parts', type: 'Inventory' },
    TRAVEL: { id: 'travel', position: 4, name: 'Travel', type: 'NonInventory' },
    EXTERNAL: { id: 'external', position: 5, name: 'External', type: 'NonInventory' },
};
export const STATUS_TABLES = {
    REQUESTS: { name: 'Service Requests', singular: 'Service Request' },
    WORKORDERS: { name: 'Work Orders', singular: 'Work Order' },
    PURCHASE_ORDERS: { name: 'Purchase Orders', singular: 'Purchase Order' },
    INVOICES: { name: 'Invoices', singular: 'Invoice' },
};
export const UNITS = {
    EACH: { id: '0', name: 'each' },
    PAIR: { id: '1', name: 'pair' },
    POUND: { id: '2', name: 'lb' },
    OUNCE: { id: '3', name: 'oz' },
    GALLON: { id: '4', name: 'gallon' },
    QUART: { id: '5', name: 'quart' },
    CASE: { id: '6', name: 'case' },
    FOOT: { id: '7', name: 'foot' },
    INCH: { id: '8', name: 'inch' },
};
export const URGENCY = {
    LOW: { id: '0', name: 'Low', color: '#cccccc' },
    MEDIUM: { id: '1', name: 'Medium', color: 'green' },
    HIGH: { id: '2', name: 'High', color: '#ffbf00' },
    URGENT: { id: '3', name: 'Urgent', color: '#ff4000' },
};
export const CHECKIN = {
    CHECKOUT: { id: '1', name: 'CheckOut', color: '#b3b3b3' },
    ENROUTE: { id: '2', name: 'Enroute', color: '#679cca' }, // id & color matches WORKORDERS
    ONSITE: { id: '3', name: 'Onsite', color: '#937eca' }, // id & color matches WORKORDERS
    INPROGRESS: { id: '4', name: 'In Progress', color: '#FF8C00' }, // id & color matches WORKORDERS
};
export const WORKORDERS = {
    UNASSIGNED: { id: '0', name: 'Unassigned', color: '#b3b3b3', disabled: false, description: 'An "Unassigned" Work Order has been created but not assigned to a technician.  This can be done by editing the Work Order, or dragging it to Technician\'s Schedule from the Schedule view.', change: 'Changing the status to "Unassigned" will remove the current technician from the Work Order and allow another Technician to be assigned.', caution: true, possible: true },
    ASSIGNED: { id: '1', name: 'Assigned', color: '#16a5a5', disabled: true, description: '"Assigned" means that the Work Order has been assigned to a technician.', change: 'You cannot change the status to "Assigned" from here.  Please edit the Work Order and assign a Technician.', caution: false, possible: false },
    ENROUTE: { id: '2', name: 'Enroute', color: '#679cca', disabled: false, description: 'A Work Order marked as "Enroute" indicates that the Technician assigned to this Work Order is currently "Enroute" to the Service Request location.', change: 'You may set the status of this Work Order to indicate the Technician is "Enroute".', caution: false, possible: true },
    ONSITE: { id: '3', name: 'Onsite', color: '#937eca', disabled: false, description: 'A Work Order marked as "Onsite" indicates that the Technician assigned to this Work Order is currently "Onsite" at the Service Request location.', change: 'You may set the status of this Work Order to indicate the Technician is "Onsite".', caution: false, possible: true },
    INPROGRESS: { id: '4', name: 'In Progress', color: '#FF8C00', disabled: false, description: 'A Work Order marked as "In Progress" indicates that the Technician assigned to this Work Order is currently "In Progress" on this Work Order.', change: 'You may set the status of this Work Order to indicate the Technician is "In Progress".', caution: false, possible: true },
    INCOMPLETE: { id: '5', name: 'Incomplete', color: '#e8e475', disabled: false, description: 'An "Incomplete" Work Order has been marked as "Incomplete" for a specified reason usually requiring followup by the Office or another Technician', updatable: true },
    COMPLETE: { id: '6', name: 'Complete', color: '#a4dd00', disabled: false, description: 'A Work Order marked as "Complete" has been updated with Line Items for materials and labor and is ready for Invoicing.', change: 'Changing the status to "Complete" enables the generation of an Invoice for this Work Order. Be sure all material and line items have been completed. ', caution: true, possible: true },
    INVOICED: { id: '7', name: 'Invoiced', color: '#68bc00', disabled: true, description: 'The Customer has been invoiced for this Work Order.', change: 'You cannot change the status to "Invoiced" from here.  Please generate an invoice from the Service Request Panel.', caution: false, possible: false },
    CLOSED: { id: '8', name: 'Closed', color: '#333333', disabled: false, description: 'A "Closed" work Order has been Completed, Invoiced, and Paid, or Incomplete and Closed due to Customer Request.  There are no pending actions.', change: 'Changing the status to "Closed" will remove the Work Order from active status and expect no further action.', caution: true, possible: true },
};

/* DEFAULTS ------------------------------------------------------------------------------------------ */

export const DEFAULT_DASHBOARDS = [
    DASHBOARDS.TECHNICIAN.id,
    DASHBOARDS.SERVICE_REQUESTS.id,
    DASHBOARDS.ASSETS.id,
]
export const DEFAULT_SCHEDULE = {
    1: { end: '17:00', isWorkDay: false, start: '07:00' },
    2: { end: '17:00', isWorkDay: true, start: '07:00' },
    3: { end: '17:00', isWorkDay: true, start: '07:00' },
    4: { end: '17:00', isWorkDay: true, start: '07:00' },
    5: { end: '17:00', isWorkDay: true, start: '07:00' },
    6: { end: '17:00', isWorkDay: true, start: '07:00' },
    7: { end: '17:00', isWorkDay: false, start: '07:00' }
};
export const ADDITIONAL_FIELDS = {
    VEHICLES: {
        id: '0',
        name: 'Vehicles',
        fields: [
            { grid: 'col-6', label: 'Year', field: 'year', type: 'text' },
            { grid: 'col-6', label: 'Make', field: 'make', type: 'text' },
            { grid: 'col-6', label: 'Model', field: 'model', type: 'text' },
            { grid: 'col-6', label: 'VIN', field: 'vin', type: 'text' },
            { grid: 'col-6', label: 'Plate #', field: 'plate', type: 'text' },
            { grid: 'col-6', label: 'Title #', field: 'title', type: 'text' },
            { grid: 'col-6', label: 'Miles', field: 'miles', type: 'text' },
            { grid: 'col-6', label: 'Engine', field: 'engine', type: 'text' },
        ],
        columns: [
            { name: "Year", field: "year" },
            { name: "Make", field: "make" },
            { name: "Model", field: "model" },
        ],
        search: [
            { name: "VIN", field: "vin", width: "0" },
            { name: "Plate #", field: "plate", width: "0" },
            { name: "Title #", field: "title", width: "0" },
        ]
    },
    EQUIPMENT: {
        id: '1',
        name: 'Equipment',
        fields: [
            { grid: 'col-6', label: 'Year', field: 'year', type: 'text' },
            { grid: 'col-6', label: 'Make', field: 'make', type: 'text' },
            { grid: 'col-6', label: 'Model', field: 'model', type: 'text' },
            { grid: 'col-6', label: 'Serial #', field: 'serial', type: 'text' },
        ],
        columns: [
            { name: "Year", field: "year" },
            { name: "Make", field: "make" },
            { name: "Model", field: "model" },
            { name: "Serial #", field: "serial" },
        ],
        search: []
    },
    PROPERTIES: {
        id: '2',
        name: 'Properties',
        fields: [
            { grid: 'col-6', label: 'Address', field: 'address', type: 'text' },
            { grid: 'col-6', label: 'City', field: 'city', type: 'text' },
            { grid: 'col-6', label: 'State', field: 'state', type: 'text' },
            { grid: 'col-6', label: 'ZIP', field: 'zip', type: 'text' },
        ],
        columns: [
            { name: "Address", field: "address" },
        ],
        search: [
            { name: "Address", field: "address", width: "0" },
        ]
    },
};

// PERMISSIONS -----------------------------------------------------------------------------------

export const PERMISSIONS_ORDER = { VIEW: 0, EDIT: 1, CREATE: 2, RESTORE: 3, DELETE: 4 };

export const PERMISSIONS = {
    ACTIVITY_LOGS_DELETE: { name: "Activity Logs - Delete", roles: ["99","0"] },
    ACTIVITY_LOGS_VIEW: { name: "Activity Logs - View", roles: ["99","0"] },
    ARCHIVES_DELETE: { name: "Archives - Delete", roles: ["99"] },
    ARCHIVES_RESTORE: { name: "Archives - Restore", roles: ["99"] },
    ARCHIVES_VIEW: { name: "Archives - View", roles: ["99"] },
    ASSETS_CREATE: { name: "Assets - Create", roles: ["99","0","3","4"] },
    ASSETS_DELETE: { name: "Assets - Delete", roles: ["99","0"] },
    ASSETS_EDIT: { name: "Assets - Edit", roles: ["99","0","2","3","4"] },
    ASSETS_VIEW: { name: "Assets - View", roles: ["0","1","99","2","3","4"] },
    ATTACHMENTS_CREATE: { name: "Attachments - Create", roles: ["99","0","2","3"] },
    ATTACHMENTS_DELETE: { name: "Attachments - Delete", roles: ["99","0","2","3"] },
    ATTACHMENT_VIEW: { name: "Attachments - View", roles: ["99","0","1","2","3","4"] },
    CALL_CENTER: { name: "Call Center", roles: ["1","99","0"] },
    CUSTOMERS_CREATE: { name: "Customers - Create", roles: ["99","0","1","3","4"] },
    CUSTOMERS_DELETE: { name: "Customers - Delete", roles: ["99","0"] },
    CUSTOMERS_EDIT: { name: "Customers - Edit", roles: ["1","99","0","2","3","4"] },
    CUSTOMERS_VIEW: { name: "Customers - View", roles: ["0","1","99","2","3","4"] },
    DASHBOARD_OVERVIEW: { name: "Dashboard - Financial Overview", roles: ["99"] },
    DASHBOARD_PROJECTS: { name: "Dashboard - Projects", roles: ["99","0","3"] },
    DASHBOARD_SERVICE_MAP: { name: "Dashboard - Field Service Map", roles: ["99","0","3"] },
    EMPLOYEES_CREATE: { name: "Employees - Create", roles: ["99","0"] },
    EMPLOYEES_DELETE: { name: "Employees - Delete", roles: ["99","0"] },
    EMPLOYEES_EDIT: { name: "Employees - Edit", roles: ["99","0"] },
    EMPLOYEES_VIEW: { name: "Employees - View", roles: ["0","99"] },
    INVOICES_EDIT: { name: "Invoices - Create/Edit", roles: ["99","0","4","2","3"] },
    INVOICES_VIEW: { name: "Invoices - View", roles: ["99","0","4","2","3"] },
    MESSAGING: { name: "Messaging", roles: ["99","0","1","2","3","4"] },
    PAYMENTS_DELETE: { name: "Payments - Delete", roles: ["99","0","4"] },
    PAYMENTS_EDIT: { name: "Payments - Edit", roles: ["99","0","4"] },
    PAYMENTS_RECEIVE: { name: "Payments - Receive", roles: ["99","0","4","2","3","1"] },
    PAYMENTS_VIEW: { name: "Payments - View", roles: ["2","99","0","4","3","1"] },
    PURCHASE_ORDERS_DELETE: { name: "Purchase Orders - Delete", roles: ["99","0","4"] },
    PURCHASE_ORDERS_EDIT: { name: "Purchase Orders - Edit", roles: ["99","0","4"] },
    PURCHASE_ORDERS_RECEIVE: { name: "Purchase Orders - Receive", roles: ["99","0","4","2","3","1"] },
    PURCHASE_ORDERS_VIEW: { name: "Purchase Orders - View", roles: ["2","99","0","4","3","1"] },
    QUOTES_CREATE: { name: "Quotes - Create", roles: ["99","0","3"] },
    QUOTES_EDIT: { name: "Quotes - Edit", roles: ["99","0","3"] },
    QUOTES_VIEW: { name: "Quotes - View", roles: ["99","0","1","2","3","4"], },
    SCHEDULE_EDIT: { name: "Schedule - Edit", roles: ["2","99","0","1","3"] },
    SCHEDULE_PERSONAL_TIME_APPROVE: { name: "Schedule - Approve/Deny Personal Time", roles: ["99","0"] },
    SCHEDULE_VIEW: { name: "Schedule - View", roles: ["99","2","0","1","3","4"] },
    SERVICE_REQUESTS_CREATE: { name: "Service Requests - Create", roles: ["2","99","0","1"] },
    SERVICE_REQUESTS_DELETE: { name: "Service Requests - Delete", roles: ["99","0"] },
    SERVICE_REQUESTS_EDIT: { name: "Service Requests - Edit", roles: ["99","2","0","1","3"] },
    SERVICE_REQUESTS_TERMS: { name: "Service Requests - Change Terms", roles: ["99","0","3","2"] },
    SERVICE_REQUESTS_VIEW: { name: "Service Requests - View", roles: ["99","2","0","1","3","4"] },
    SETTINGS_ACCOUNTING: { name: "Settings - Accounting", roles: ["99","0", "4"] },
    SETTINGS_ASSETS: { name: "Settings - Assets", roles: ["99"] },
    SETTINGS_COMPANY: { name: "Settings - Company", roles: ["99"] },
    SETTINGS_IMPORT_EXPORT: { name: "Settings - Import / Export", roles: ["99"] },
    SETTINGS_INVENTORY: { name: "Settings - Inventory", roles: ["99"] },
    SETTINGS_JOB_CATEGORIES: { name: "Settings - Job Categories", roles: ["99"] },
    SETTINGS_PREFERENCES: { name: "Settings - Preferences", roles: ["99", "0"] },
    SETTINGS_QUICKBOOKS: { name: "Settings - QuickBooks", roles: ["99","0", "4"] },
    SETTINGS_SCHEDULE: { name: "Settings - Schedule", roles: ["99"] },
    SETTINGS_SERVICE_ITEMS: { name: "Settings - Service Items", roles: ["2","99","0","3"] },
    STOCK_PARTS_CATEGORIES: { name: "Stock Parts - New Categories", roles: ["99","0","2","3"] },
    STOCK_PARTS_CREATE: { name: "Stock Parts - Create", roles: ["2","99","0","3"] },
    STOCK_PARTS_DELETE: { name: "Stock Parts - Delete", roles: ["99","0"] },
    STOCK_PARTS_EDIT: { name: "Stock Parts - Edit", roles: ["2","99","0","3"] },
    STOCK_PARTS_VIEW: { name: "Stock Parts - View", roles: ["99","2","0","1","3","4"] },
    VENDORS_CREATE: { name: "Vendors - Create", roles: ["2","99","0","3"] },
    VENDORS_DELETE: { name: "Vendors - Delete", roles: ["99","0"] },
    VENDORS_EDIT: { name: "Vendors - Edit", roles: ["2","99","0","3"] },
    VENDORS_VIEW: { name: "Vendors - View", roles: ["99","2","0","1","3","4"] },
    WORK_ORDERS_CREATE: { name: "Work Orders - Create", roles: ["99","0","1","2","3"] },
    WORK_ORDERS_DELETE: { name: "Work Orders - Delete", roles: ["2","99","0"] },
    WORK_ORDERS_EDIT: { name: "Work Orders - Edit", roles: ["2","99","0","1","3"] },
    WORK_ORDERS_VIEW: { name: "Work Orders - View", roles: ["99","0","1","4","2","3"] },
}

// -------------------------------------------------------------------------------------------
// FLUTTER MECH ARMOR -----------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

/*  
    title - to be displayed in App Status bar
    icon - to be displayed on tab
    link - 'id' of link in Navbar to be triggered
    path - start of path(s) to match this setting (split on '|')
    permissions: permission setting required for access
    subment - creation of subtabs - one entire entry required for each tab
*/

export const FLUTTER_TABS = [
    { title: 'Asset Manager', icon: 'home', link: 'link-technicians', path: 'dashboard' },
    { title: 'Maintenance', icon: 'construction', link: 'link-assets', path: 'assets|customers' },
    { title: null },
    { title: 'Stock Parts', icon: 'home_repair_service', link: 'link-stock-parts', path: 'stock_parts|vendors' },
    { title: 'Employees', icon: 'badge', link: 'link-employees', path: 'employees' },
    // { title: 'Schedule', icon: 'event_note', link: 'link-schedule', path: 'schedule' },
];

export const FLUTTER_DRAWER_NAV = [
    { title: 'User Preferences', icon: 'mobile_friendly', link: 'link-user-prefs' },
    // { title: 'Employees', icon: 'badge', link: 'link-employees', permissions: 'EMPLOYEES_VIEW' },
]

export const FLUTTER_SETTINGS_NAV = [
    { title: 'Company Info', icon: 'info', link: 'link-settings-company', permissions: 'SETTINGS_COMPANY' },
    { title: 'Departments', icon: 'settings', link: 'link-settings-departments', permissions: 'SETTINGS_COMPANY' },
    { title: 'Asset Settings', icon: 'checklist', link: 'link-settings-assets', permissions: 'SETTINGS_ASSETS' },
    { title: 'Inventory Settings', icon: 'home_repair_service', link: 'link-settings-inventory', permissions: 'SETTINGS_INVENTORY' },
    { title: 'Schedule Settings', icon: 'event_note', link: 'link-settings-schedule', permissions: 'SETTINGS_SCHEDULE' },
]

// Must be kept in order so that the last matched path is retained as the retained match
export const  FLUTTER_NAV = [
    { title: 'Login', path: 'login' },
    { title: 'Register', path: 'register_company' },
    { title: 'Delete Account', path: 'account_delete' },

    /* Asset Manager ----------------------------------------------------------------------*/
    { title: 'Asset Manager', path: 'dashboard/*'},
    
    /* Maintenance ----------------------------------------------------------------------*/
    { title: 'Maintenance', path: 'assets/*/*', submenu: [
        { title: 'Assets', link: 'link-assets', active: true },
        { title: 'Customers', link: 'link-customers', active: false },
    ]},
    { title: 'Maintenance', path: 'customers', submenu: [
        { title: 'Assets', link: 'link-assets', active: false },
        { title: 'Customers', link: 'link-customers', active: true },
    ]},

    { title: 'Customer Details', path: 'customers/*' },
    { title: 'Asset Details', path: 'assets/*/*/*' },
    { title: 'Asset Service Request', path: 'assets/*/*/*/*/*/service_requests/*' },
    { title: 'Asset Work Order', path: 'assets/*/*/*/*/*/service_requests/*/work_orders/*' },
    { title: 'Asset Stock Part', path: 'assets/*/*/*/*/*/stock_parts/*' },

    /* Stock Parts --------------------------------------------------------------------------*/
    { title: 'Stock Parts', path: 'stock_parts', submenu: [
        { title: 'Inventory', link: 'link-stock-parts', active: true },
        { title: 'Vendors', link: 'link-vendors', active: false },
        // { title: 'Purchase Orders', link: 'link-purchase-orders', active: false },
    ]},
    { title: 'Stock Parts', path: 'vendors', submenu: [
        { title: 'Inventory', link: 'link-stock-parts', active: false },
        { title: 'Vendors', link: 'link-vendors', active: true },
        // { title: 'Purchase Orders', link: 'link-purchase-orders', active: false },
    ]},
    // { title: 'Stock Parts', path: 'purchase-orders', submenu: [
    //     { title: 'Inventory', link: 'link-stock-parts', active: false },
    //     { title: 'Vendors', link: 'link-vendors', active: false },
    //     { title: 'Purchase Orders', link: 'link-purchase-orders', active: true },
    // ]},

    // { title: 'Employees', path: 'employees' },
    // { title: 'Employees', path: 'employees/*/*/*/*', back_link: 'link-employees' },
    { title: 'Settings', path: 'settings/company' },
    { title: 'Settings', path: 'settings/departments' },
    { title: 'Settings', path: 'settings/assets' },
    { title: 'Settings', path: 'settings/user_preferences' },
    { title: 'Settings', path: 'settings/inventory' },
    { title: 'Settings', path: 'settings/schedule' },
    { title: 'Stock Part Details', path: 'stock_parts/*/*' },
    { title: 'Vendor Details', path: 'vendors/*' },
    { title: 'Vendor Purchase Order', path: 'vendors/*/*/*/purchase_orders/*' },
]

// ACTIVITY LOGS -----------------------------------------------------------------------------------

export const LOG = {
    ADDRESS: { key: 'ADDRESS', name: 'Address' },
    ASSET: { key: 'ASSET', name: 'Asset' },
    ASSET_TYPE: { key: 'ASSET_TYPE', name: 'Asset Type' },
    ASSET_CATEGORY: { key: 'ASSET_CATEGORY', name: 'Asset Category' },
    CALL_LOG: { key: 'CALL_LOG', name: 'Call Log' },
    CONTACT: { key: 'CONTACT', name: 'Contact' },
    COMPANY: { key: 'COMPANY', name: 'Company Settings' },
    CUSTOMER: { key: 'CUSTOMER', name: 'Customer' },
    DEPARTMENT: { key: 'DEPARTMENT', name: 'Department' },
    EMPLOYEE: { key: 'EMPLOYEE', name: 'Employee' },
    IMPORT_EXPORT: { key: 'IMPORT_EXPORT', name: 'Import/Export' },
    MESSAGE: { key: 'MESSAGE', name: 'Message' },
    PARTS_CATEGORY: { key: 'PARTS_CATEGORY', name: 'Parts Category' },
    PURCHASE_ORDER: { key: 'PURCHASE_ORDER', name: 'Purchase Order' },
    QUOTE: { key: 'QUOTE', name: 'Quote' },
    SCHEDULE: { key: 'SCHEDULE', name: 'Schedule' },
    SERVICE_REQUEST: { key: 'SERVICE_REQUEST', name: 'Service Request' },
    SETTINGS: { key: 'SETTINGS', name: 'Settings' },
    STOCK_PART: { key: 'STOCK_PART', name: 'Stock Part' },
    USER: { key: 'USER', name: 'User' },
    VENDOR: { key: 'VENDOR', name: 'Vendor' },
    WORK_ORDER: { key: 'WORK_ORDER', name: 'Work Order' },
    WORK_ORDER_SCHEDULE: { key: 'WORK_ORDER_SCHEDULE', name: 'Work Order Schedule' },
};

export const ACTION = {
    VIEWED: { key: 'VIEWED', name: 'Viewed' },
    CREATED: { key: 'CREATED', name: 'Created' },
    MODIFIED: { key: 'MODIFIED', name: 'Modified' },
    DELETED: { key: 'DELETED', name: 'Deleted' },
    ARCHIVED: { key: 'ARCHIVED', name: 'Archived' },
    RESTORED: { key: 'RESTORED', name: 'Restored' },
    GENERATED: { key: 'GENERATED', name: 'Generated' },
    PRINTED: { key: 'PRINTED', name: 'Printed' },
    EMAILED: { key: 'EMAILED', name: 'Emailed' },
    MISC: { key: 'MISC', name: 'Misc' },
}
