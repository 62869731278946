import React from 'react';
import { numPanels } from 'components/common/toolbox';

export default function SlidingColumn(props) {

    const { index, columnState, children} = props;

    const findLeft = (index) => {
        let result = 0;
        let i = index;
        let ii = 0;
        do {
            if (columnState?.['panelSlide'+index] == ii) {
                result = columnState['panelPos'+i];
                break;
            }
            i--; ii ++;
        } while (i > 1);
        return result;
    }

    const style = { left: findLeft(index) };
    const overhead = (window.flutter) ? '0px' : (numPanels(1)) ? '53px' : '114px';

    var shiftUp = true;
    if (index == 2 && columnState?.panelSlide2 >= 1) shiftUp = false;
    if (index == 3 && columnState?.panelSlide3 >= 2) shiftUp = false;

    var shift = (props.shift) ? props.shift : 'mt-n5';
 
    return (
        <>
            { index == 0
                ?   <></>
                :   <>
                        { index == 1
                            ?   <div id={`column${index}`} 
                                    className={`column col-lg-6 ${(props.twocolumns)?'col-xl-6':'col-xl-4'} px-2 px-lg-3 animate-opacity`} 
                                    style={{overflowY: 'auto', overflowX: 'hidden', scrollwidth: columnState?.panelWidth, height: `calc(100vh - ${overhead})` }}>
                                    { children }
                                </div>
                            :   <div id={`column${index}`} 
                                    className={`column col-lg-6 ${(props.twocolumns)?'col-xl-6':'col-xl-4'} px-2 px-lg-3 animate-left ${(!numPanels(1) && shiftUp)?shift:''}`} 
                                    style={{ ...style, overflowY: 'auto', overflowX: 'hidden', scrollwidth: columnState?.panelWidth, height: `calc(100vh - ${overhead})` }}>
                                    { children }
                                </div>
                        }
                    </>
            }
        </>
    );
};
