import * as actions_admin from 'actions/admin-actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function HelpPanel() {

	const dispatch = useDispatch();
	const admin = useSelector((store) => store.admin);

	useEffect(() => {
		let iframe = document.getElementById("myIframe");
		iframe.addEventListener('load', handleLoad, true);

		return () => {
			iframe.removeEventListener("load", handleLoad, true);
		}
	});

	const handleLoad = () => {
		setTimeout(() => {
			let tag = admin.help_url.split('#')[1];
			if (tag && window.location.hostname != 'localhost') {
				let iframe = document.getElementById("myIframe");
				let element = iframe.contentWindow.document.getElementById(tag);
				let link = element.getElementsByTagName("a");
				link[0].click();
			}
		}, 500);
	}
	
	const closeHelpPanel = () => {
		dispatch(actions_admin.showHelp(false));
	}

	return (
		<>
			<div className={ `bs-canvas bs-canvas-right position-fixed bg-light h-100 ${(admin.help_show)?'mr-0':''}` }>
				<header className="bs-canvas-header p-3 bg-primary overflow-auto">
					<button type="button" className="bs-canvas-close float-left close" aria-label="Close" onClick={ closeHelpPanel }><span aria-hidden="true" className="text-light">&times;</span></button>
					<h4 className="d-inline-block text-light mb-0 float-right">Help Documentation</h4>
				</header>
				<div className="bs-canvas-content">
					<iframe id="myIframe" src={ admin.help_url } style={{ height: 'calc(100vh - 62px)', width: '100%', border: 'none' }}></iframe>
				</div>    
			</div>
			{ admin.help_show &&
				<div className={ `modal-backdrop fade ${(admin.help_show)?'show':''}` } role="presentation"></div>
			}
		</>
	);
}
