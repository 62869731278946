import * as actions_admin from 'actions/admin-actions';
import * as actions_authentication from 'actions/authentication-actions';
import React, { useState } from 'react';
import StatusModal from 'components/common/status-modal';
import store from 'store';
import { APPS } from 'components/common/constants';
import { Link } from "react-router-dom";
import { numPanels } from 'components/common/toolbox';
import { sortByPosition } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Lightbox from "yet-another-react-lightbox";
import { Thumbnails, Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

var moment = require('moment');

export default function Navbar(props) {

    const dispatch = useDispatch();
	const routeLocation = useLocation();
    const segments = routeLocation.pathname.split('/');

    const admin = useSelector((store) => store.admin);
    const assetTypes = useSelector((store) => store.settings.settings_asset_types)?.filter((o) => !o.deleted);
    const company = useSelector((store) => store.company.company);
    const selectedCustomer = useSelector((store) => store.assets.customer);
    const selectedType = useSelector((store) => store.assets.asset_type);
    const statusModal = useSelector((store) => store.settings.statusModal);

    const targetAssetTypeId = (selectedType && assetTypes?.find((at) => at?.id == selectedType)) ? selectedType : (assetTypes) ? sortByPosition(assetTypes)[0]?.id : '';
    const targetCustomerId = (selectedCustomer) ? selectedCustomer : '0';

    const [focusSearch, setFocusSearch] = useState(false);

    const logOut = (event) => {
        event.preventDefault();
        store.dispatch(actions_authentication.logout());
    }
    const selectApp = (app) => dispatch(actions_admin.selectApp(app));
    const openHelp = () => dispatch(actions_admin.showHelp(true, '/getting-started/'));

    const MobileLink = (props) => 
        <li className="nav-item">
            <Link replace={ !props.push } id={props.id} className={props.className} to={props.to} data-toggle={ (window.flutter)?'':'collapse'} data-target=".navbar-collapse">{props.name} </Link>
        </li>

    console.log(company);

    return (

        <div className="row border-bottom white-bg" style={{ marginLeft: (numPanels(1)) ? '0':'80px', ...(window.flutter)?{display: 'none'}:{} }}>
            <nav className="navbar navbar-expand-lg navbar-static-top" 
                style={{ justifyContent: 'normal', ...(numPanels(1)) ? { backgroundColor: '#a68d42' } : {}}} 
                role="navigation"
            >
                <span className="position-relative" style={{ flexWrap: 'nowrap' }}>
                    <button className="navbar-brand" style={{ border: 'none' }}>
                        <a className="navbar-toggler collapsed m-0 p-0 pr-4" type="button" data-toggle="collapse" data-target="#navbar">
                            <i className="fa fa-bars" style={{ fontSize: '18px' }}></i>
                        </a>
                        <img src="images/logo-white-small.png" height="25" />
                        {process.env.REACT_APP_FIREBASE_ENV == 'development' &&
                            <small style={{ color: 'red' }}>{process.env.REACT_APP_FIREBASE_ENV?.toUpperCase()}</small>
                        }
                    </button>
                </span>

                <div className="navbar-collapse collapse" id="navbar">
                    <ul className="navbar-nav mr-auto">
                        { company?.appIds?.includes(APPS.SERVICE.id) &&
                            <li className={ `nav-item ${(admin.activeAppId == APPS.SERVICE.id)?'active':''}` } onClick={ () => selectApp(APPS.SERVICE.id) }>
                                <a className="nav-link">Service Manager</a>
                            </li>
                        }
                        { company?.appIds?.includes(APPS.ASSETS.id) &&
                            <li className={ `nav-item ${(admin.activeAppId == APPS.ASSETS.id)?'active':''}` } onClick={ () => selectApp(APPS.ASSETS.id) }>
                                <a className="nav-link">Asset Manager</a>
                            </li>
                        }
                        { company?.appIds?.includes(APPS.LOGISTICS.id) &&
                            <li className={ `nav-item ${(admin.activeAppId == APPS.LOGISTICS.id)?'active':''}` } onClick={ () => selectApp(APPS.LOGISTICS.id) }>
                                <a className="nav-link">Logistics Manager</a>
                            </li>
                        }
                    </ul>
                    <ul className="nav navbar-nav mr-auto bg-white">    
                        { numPanels(1) &&
                            <>
                                <MobileLink name="Home" id="link-home" to={`/${props.handle}/dashboard`} />
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="dashboard-submenu" data-toggle="dropdown">Dashboard</a>
                                    <ul className="dropdown-menu" aria-labelledby="dashboard-submenu">
                                        <MobileLink name="Technician" id="link-technicians" to={`/${props.handle}/dashboard/technicians`} />
                                        <MobileLink name="Service Requests" id="link-service-requests" to={`/${props.handle}/dashboard/service_requests`} />
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="maintenance-submenu" data-toggle="dropdown">Maintenance</a>
                                    <ul className="dropdown-menu" aria-labelledby="maintenance-submenu">
                                        <MobileLink name="Assets" id="link-assets" to={`/${props.handle}/assets/${targetCustomerId}/${targetAssetTypeId}`} />
                                        <MobileLink name="Customers" id="link-customers" to={`/${props.handle}/customers${(parseInt(targetCustomerId) && !window.flutter) ? `/${targetCustomerId}/form/0/stock_parts` : ''}`} />
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="stock-parts-submenu" data-toggle="dropdown">Stock Parts</a>
                                    <ul className="dropdown-menu" aria-labelledby="stock-parts-submenu">
                                        <MobileLink name="Inventory" id="link-stock-parts" to={`/${props.handle}/stock_parts/${targetAssetTypeId}`} />
                                        <MobileLink name="Vendors" id="link-vendors" to={`/${props.handle}/vendors`} />
                                    </ul>
                                </li>
                                <MobileLink name="Schedule" id="link-schedule" to={`/${props.handle}/schedule/${moment().format('YYYY-MM-DD')}`} />
                                <MobileLink name="Employees" id="link-employees" to={`/${props.handle}/employees/search`} />
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="settings-submenu" data-toggle="dropdown">Settings</a>
                                    <ul className="dropdown-menu" aria-labelledby="settings-submenu">
                                        <MobileLink push={ segments[2] !== 'settings' } name="Company" id="link-settings-company" to={`/${props.handle}/settings/company`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Departments" id="link-settings-departments" to={`/${props.handle}/settings/departments`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Assets" id="link-settings-assets" to={`/${props.handle}/settings/assets`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Inventory" id="link-settings-inventory" to={`/${props.handle}/settings/inventory`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Schedule" id="link-settings-schedule" to={`/${props.handle}/settings/schedule`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="User Preferences" id="link-user-prefs" to={`/${props.handle}/settings/user_preferences`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Delete Account" id="link-delete-account" to={`/${props.handle}/account_delete`} className="text-danger" />
                                    </ul>
                                </li>
                                <li className="nav-item"><p className="float-right px-4">{ window.build }</p></li>
                            </> 
                        }
                    </ul>
                    <div className="navbar-header bg-white" style={{ width: (focusSearch) ? '50%':'20%', transition: 'width .4s' }}>
                        <form role="search" className="navbar-form-custom" action="search_results.html">
                            <div className="form-group">
                                <input type="text" 
                                    id="top-search"
                                    className="form-control" 
                                    style={{ width: '100%' }} 
                                    name="top-search" 
                                    placeholder="Search for something..." 
                                    onFocus={ () => setFocusSearch(true) }
                                    onBlur={ () => setFocusSearch(false) }
                                />
                            </div>
                        </form>
                    </div>
                    <ul className="nav navbar-top-links navbar-right bg-white pr-3">
                        <li><a title="help" id="link-help" style={{ padding: '10px' }} role="button" onClick={openHelp}> <i className="fas fa-question-circle fa-2x text-muted"></i></a></li>
                        <li><a title="logout" id="link-logout" style={{ padding: '10px' }} role="button" onClick={logOut}> <i className="fas fa-sign-out-alt fa-2x text-muted"></i></a></li>
                    </ul>
                </div>
            </nav>
            {statusModal &&
                <StatusModal />
            }
            <Lightbox
                open={admin.lightbox_show}
                close={() => dispatch(actions_admin.lightboxShow(false))}
                slides={admin.lightbox_sources}
                plugins={[Thumbnails, Zoom]}
                zoom={{
                    maxZoomPixelRatio: 4,
                    pinchZoomDistanceFactor: 200,
                }}
                carousel={{ finite: true }}
                render={{
                  buttonPrev: admin.lightbox_sources.length <= 1 ? () => null : undefined,
                  buttonNext: admin.lightbox_sources.length <= 1 ? () => null : undefined,
                }}
                toolbar={{
                    buttons: [
                      <button key="my-button" type="button" className="yarl__button">
                        <span className="material-icons">add_a_photo</span>
                      </button>,
                      "close",
                    ],
                }}
            />
        </div>
    );
}
