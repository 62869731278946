import React from 'react';
import ArchiveSearch from 'components/archive/archive-search';
import { Route } from "react-router-dom";
import CatchErrors from 'components/common/catch-errors';


export default class ArchivedPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            segments: 8, // i.e. /#/:handle/service/:id/:tab/:work_order_id/:item_id
            service_item: {},
        };
    }

    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
    }
    componentWillUnmount() {
    }

    render() {

        return (

            <div className="col-12">
                <div className="row">
                    {/* ----------------------------------------------------------------------
                        COLUMN 1 - /:handle/:service_item_id
                    ---------------------------------------------------------------------- */}

                    <Route path={this.props.match.path + '/:service_item_id?'} render={(route_props) =>
                        <div id="column1" className="column col-8 animate-opacity" style={{ height: 'calc(100vh - 100px)', overflowX: 'hidden' }}>
                            <h1><strong>ARCHIVED ITEMS</strong></h1>
                            <CatchErrors>
                                <ArchiveSearch {...route_props} />
                            </CatchErrors>
                        </div>
                    } />

                </div>
            </div>
        );
    }
};

