import * as actions_chat from 'actions/chat-actions-port';
import ChatConversation from './chat-conversation';
import ChatMessage from './chat-message';
import React, { useEffect, useRef, useState } from 'react';
import UserList from './user-list';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { Avatar, ModalAlert, CloseX } from 'enspire-manager-framework';
import { Button, ButtonGroup } from 'react-bootstrap';
import { sortByField } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const Chat = (props) => {

    /* Hooks --------------------------*/

    const hiddenFileInput = useRef();
    const messageBox = useRef(null);
    const params = useParams();
    const dispatch = useDispatch();
    const user = useSelector((store) => store.users.user);
    var users = {};
    const employees = useSelector((store) => store.employees.employees);
    employees.forEach((employee) => {
        if (employee.contact?.email) users[employee.contact.email] = { ...employee };
    });
    const [selected, setSelected] = useState();
    const [message, setMessage] = useState('');
    const [attachment, setAttachment] = useState('');
    const [attachmentLoading, setAttachmentLoading] = useState(false);
    const [type, setType] = useState('individual');
    const [addUserOpen, setAddUserOpen] = useState(false);
    const conversations = useSelector((store) => store.chat.conversations)?.map((conversation) => ({ ...conversation, userIds: conversation.userIds.filter((id) => id != user.id) }));
    const individualConversations = sortByField(conversations, 'lastDate', true)?.filter((conversation) => conversation.userIds.length == 1);
    const groupConversations = sortByField(conversations, 'lastDate', true)?.filter((conversation) => conversation.userIds.length >= 2);
    const messages = useSelector((store) => store.chat.messages);
    const chat = useSelector((store) => store.chat);
    const [newConversation, setNewConversation] = useState();

    // Close conversation on chat Close
    useEffect(() => {
        if (!props.chatOpen) {
            setSelected('');
        }
    }, [props.chatOpen]);

    // If User is logged in, subscribe to unread messages and conversations
    useEffect(() => {
        if (user.id) {
            dispatch(actions_chat.subConversations(params.handle, user.id));
            dispatch(actions_chat.subUnreadMessages(params.handle, user.id));
        }

        return () => {
            var unsubscribe;

            unsubscribe = chat.conversations_unsubscribe;
            if (typeof unsubscribe == 'function') {
                unsubscribe();
            }

            unsubscribe = chat.unread_messages_unsubscribe;
            if (typeof unsubscribe == 'function') {
                unsubscribe();
            }
        };
    }, [user]);
    // If conversation selected, subscribe to messages in that conversation
    useEffect(() => {
        if (selected) {
            if (typeof chat.messages_unsubscribe == 'function') {
                chat.messages_unsubscribe();
            }
            dispatch(actions_chat.subMessages(params.handle, selected));
        }

        return () => {
            var unsubscribe = chat.messages_unsubscribe;
            if (typeof unsubscribe == 'function') {
                unsubscribe();
            }
        };
    }, [selected]);
    // Set messageBox on mount
    useEffect(() => {
        if (messageBox) {
            messageBox.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, []);
    // Read messaegs when read
    useEffect(() => {
        if (messages) {
            var unread = messages.filter((message) => message.unreadBy.includes(user.id));
            if (unread.length > 0) {
                unread = unread.map((message) => ({ ...message, unreadBy: message.unreadBy.filter((o) => o != user.id) }));
                if (selected) dispatch(actions_chat.readMessages(params.handle, unread, selected));
            }
        }
    }, [messages]);

    /* Handlers --------------------------*/

    const handleCloseX = () => {
        props.setChatOpen(false);
    };
    const handleSelect = (conversationId) => {
        if (conversation?.id == conversationId) setSelected('');
        else setSelected(conversationId);
    };
    const chooseFile = (e) => {
        var file = e.target.files[0];
        uploadFirestore(file);
    };
    const uploadFirestore = (file) => {
        setAttachmentLoading(true);
        var metadata = { contentType: file.type };
        var ext = '';
        if (file.type.endsWith('pdf')) ext = '.pdf';
        if (file.type.endsWith('jpeg')) ext = '.jpg';
        if (file.type.endsWith('png')) ext = '.png';
        if (file.type.endsWith('pdf')) ext = '.pdf';
        if (file.type.endsWith('xlsx')) ext = '.xlsx';
        if (file.type.endsWith('sheet')) ext = '.xlsx';

        var filename = file.name;
        var uploadTask = firebase.storage().ref().child(params.handle + '/' + filename).put(file, metadata);

        uploadTask.on('state_changed', (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        }, (error) => {
            console.error(error);
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                setAttachment({ downloadURL, filename, type: file.type });
                setAttachmentLoading(false);
            });
        });
    };

    const handleSend = (e) => {
        if (!conversation) {
            window.toastr.error("No conversation selected", "Message not sent");
            return;
        }
        if (!attachment && !message) {
            return;
        }
        if (attachmentLoading) {
            window.toastr.error("Please wait for the attachment to finish uploading", "Message not sent");
            return;
        }
        if (newConversation) {
            const copy = _.cloneDeep(newConversation);
            setNewConversation();

            dispatch(actions_chat.addConversation(params.handle, [...copy.userIds, user.id], (conversation) => {
                var messageData = { date: new Date(), message, unreadBy: copy.userIds, userId: user.id, conversationId: conversation.id };
                setSelected(conversation.id);
                dispatch(actions_chat.sendMessage(params.handle, conversation.id, messageData, user.id, userList, () => {
                    hiddenFileInput.current.value = '';
                    setAttachment('');
                    setMessage('');
                }));
            }));
        }
        else {
            const messageData = { date: new Date(), message, unreadBy: conversation.userIds, userId: user.id, attachment, conversationId: conversation.id };
            dispatch(actions_chat.sendMessage(params.handle, conversation.id, messageData, user.id, userList, () => {
                hiddenFileInput.current.value = '';
                setAttachment('');
                setMessage('');
            }));
        }
    };
    const handleAddToExisting = (recipient) => {
        if (conversation.userIds.includes(recipient.contact.email)) {
            window.toastr.error("This user is already apart of this conversation.", "User already added!");
            return;
        }

        if (newConversation) {
            setNewConversation(prev => ({ ...prev, userIds: [...prev.userIds, recipient.contact.email] }));
        }
        else {
            dispatch(actions_chat.updateConversation(params.handle, { userIds: [...conversation.userIds, user.id, recipient.contact.email] }, conversation.id, () => {
                setType('group');
            }));
        }
        setAddUserOpen(false);
    };

    const handleAdd = (recipient) => {
        if (!conversation) {
            setSelected("NEW_CONVERSATION");
            setAddUserOpen(false);
            setNewConversation({ userIds: [recipient.contact.email], id: "NEW_CONVERSATION" });
        }
        else {
            ModalAlert({
                title: 'Existing or New?',
                text: "Add user to existing conversation or create a new one?",
                icon: 'warning',
                show_cancel: true,
                confirm_color: '#8FBC8B',
                confirm_text: 'Create New',
                show_deny: true,
                show_cancel: false,
                deny_text: 'Add to Existing',
                deny_color: '#23c6c8',
                callback_denied: () => { handleAddToExisting(recipient); },
                callback_success: () => {
                    setSelected("NEW_CONVERSATION");
                    setAddUserOpen(false);
                    setNewConversation({ userIds: [recipient.contact.email] });
                }
            }
            );
        }
    };

    /* Constants --------------------------*/

    const conversation = (conversations?.find((conversation) => conversation.id == selected)) ? conversations?.find((conversation) => conversation.id == selected) : newConversation;
    const userList = conversation?.userIds.map((userId) => users[userId]?.firstName + ' ' + users[userId]?.lastName).join(", ");

    return (
        <div className="container" style={{ display: (props.chatOpen) ? "inline" : "none" }}>
            <div className="row clearfix">
                <div className="col-lg-12">
                    <div className="card chat-app">
                        <div id="plist" className="people-list">
                            <ButtonGroup size="sm" className="py-2 d-flex justify-content-center mx-3">
                                <Button variant={(type == 'individual') ? 'primary' : "default"} onClick={() => { setType('individual'); }} >Individual</Button>
                                <Button variant={(type == 'group') ? 'primary' : "default"} onClick={() => { setType('group'); }} > Group </Button>

                            </ButtonGroup>
                            <ul className="list-unstyled chat-list mt-2 mb-0">
                                {(type == "individual")
                                    ? <>
                                        {newConversation?.userIds?.length == 1 && <ChatConversation selected={selected == "NEW_CONVERSATION"} user={users[newConversation.userIds[0]]} conversation={newConversation} onClick={handleSelect} />}
                                        {individualConversations?.map((o) => <ChatConversation key={o?.id} selected={o?.id == conversation?.id} user={users[o.userIds?.[0]]} conversation={o} onClick={handleSelect} />)}
                                    </>
                                    : <>
                                        {newConversation?.userIds?.length > 1 && <ChatConversation selected={selected == "NEW_CONVERSATION"} user={users[newConversation.userIds[0]]} conversation={newConversation} onClick={handleSelect} />}
                                        {groupConversations?.map((o) => <ChatConversation key={o?.index} selected={o?.id == conversation?.id} user={users[o.userIds?.[0]]} conversation={o} onClick={handleSelect} />)}
                                    </>}
                            </ul>
                        </div>
                        <div className="chat">
                            <div className="chat-header clearfix">
                                <div className="row">
                                    {
                                        (conversation)
                                            ? (conversation.userIds.length > 1)
                                                ? <>
                                                    <div className="col-6" data-toggle={(conversation.userIds.length > 2) && 'tooltip'} title={(conversation.userIds.length > 2) && userList}>
                                                        <div className={`avatars ${(conversation.userIds.length >= 4) ? "offSet-4" : (conversation.userIds.length == 3) ? 'offSet-3' : 'offSet-2'} justify-content-end`} >
                                                            {conversation?.userIds?.map((userId, index) => {

                                                                if (index < 3 || index == 3 && conversation.userIds.length == 4) {
                                                                    return <Avatar
                                                                        key={index}
                                                                        className={'avatar'}
                                                                        color="white"
                                                                        bgColor="grey"
                                                                        size={50}
                                                                        name={`${users[userId]?.firstName} ${users[userId]?.lastName}`}
                                                                        image={users[userId]?.photoUrl}
                                                                        fontSize={20}
                                                                    />;
                                                                } else if (index == 3) {
                                                                    return <Avatar
                                                                        key={index}
                                                                        className={'avatar'}
                                                                        color="white"
                                                                        bgColor="grey"
                                                                        size={50}
                                                                        name={`+ ${conversation.userIds.length - 3}`}
                                                                        fontSize={20}
                                                                    />;
                                                                }
                                                            })}
                                                        </div>
                                                        <div className={`chat-about-group ${(conversation.userIds.length >= 4) ? "groupOffSet-4" : (conversation.userIds.length == 3) ? 'groupOffSet-3' : 'groupOffSet-2'} my-auto`}>
                                                            <h6 className="m-b-0">{(conversation.userIds.length == 2)
                                                                ? users[conversation.userIds?.[0]]?.firstName + ' ' + users[conversation.userIds?.[0]]?.lastName + ' & ' + users[conversation.userIds?.[1]]?.firstName + ' ' + users[conversation.userIds?.[1]]?.lastName
                                                                : users[conversation.userIds?.[0]]?.firstName + ' ' + users[conversation.userIds?.[0]]?.lastName + ' & ' + (conversation.userIds.length - 1) + ' others'
                                                            }</h6>
                                                            <small>INSERT STATUS HERE</small>
                                                        </div>
                                                    </div>
                                                </>
                                                : <>
                                                    <div className="col-6 d-flex">
                                                        <Avatar
                                                            color="white"
                                                            bgColor="grey"
                                                            size={50}
                                                            name={`${users[conversation?.userIds?.[0]]?.firstName} ${users[conversation.userIds?.[0]]?.lastName}`}
                                                            image={users[conversation.userIds?.[0]]?.photoUrl}
                                                            fontSize={20}
                                                        />
                                                        <div className="chat-about">
                                                            <h6 className="m-b-0">{users[conversation.userIds?.[0]]?.firstName + ' ' + users[conversation.userIds?.[0]]?.lastName}</h6>
                                                            <small>INSERT STATUS HERE</small>
                                                        </div>
                                                    </div>
                                                </>
                                            : <>
                                            </>}
                                    <div className='mb-3'>
                                        <CloseX onClick={handleCloseX} />
                                    </div>
                                </div>
                            </div>
                            <div className="chat-history" ref={messageBox}>
                                <ul className="m-b-0">
                                    {(messages && selected) && sortByField(messages, 'date').map((message) => <ChatMessage key={message} message={message} user={user} sender={users[message.userId]} />)}
                                </ul>
                            </div>
                            <div className='position-fixed add-user-list' style={{ display: (addUserOpen) ? "inline" : 'none' }}>
                                <div className="list-unstyled chat-list mt-2 mb-0">
                                    {users && Object.keys(users)?.map((user) => <UserList key={user} user={users[user]} onClick={handleAdd} />)}
                                </div>
                            </div>
                            <div className="chat-message clearfix">
                                <div className="input-group mb-0">
                                    <div className="input-group-prepend">
                                        {(attachmentLoading)
                                            ? <a className="btn btn-outline-primary" onClick={() => { hiddenFileInput.current.click(); }}><i className="fa fa-spinner fa-spin"></i></a> : !attachment ? <a className="btn btn-outline-primary" onClick={() => { hiddenFileInput.current.click(); }}><i className="fa fa-paperclip"></i></a>
                                                : <a className="btn btn-outline-danger" onClick={() => {
                                                    hiddenFileInput.current.value = '';
                                                    setAttachment('');
                                                }}><i className="fa fa-times"></i></a>}
                                    </div>
                                    <input type="text" className="form-control" placeholder="Enter text here..." value={message} onChange={(e) => { setMessage(e.target.value); }} onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button" onClick={handleSend}><i className="fa fa-paper-plane"></i></button>
                                    </div>

                                    <input ref={hiddenFileInput} type="file" accept="image/png, image/jpeg, .pdf, .xlsx, .csv" style={{ display: 'none' }} onChange={chooseFile} filename={attachment.filename} />
                                    <button className='btn btn-secondary mx-1 px-3' onClick={() => { setAddUserOpen(!addUserOpen); }}>+ User</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;