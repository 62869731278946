import * as types from 'actions/action-types';

var initialState = {
	user: {},

	user_permissions: {},

	user_locations: [],
	user_locations_pending: false,
	user_locations_save_pending: false,
	user_locations_unsubscribe: false,

	users: [],
	users_pending: false,
	users_save_pending: false,
	users_unsubscribe: false,

	time_clock: {},
	time_clock_pending: false,
	time_clock_save_pending: false,
	time_clock_unsubscribe: false,

	time_entries: [],
	time_entries_pending: false,
	time_entries_save_pending: false,
	time_entries_unsubscribe: false,

	time_sheet: {},
	time_sheet_pending: false,
	time_sheet_save_pending: false,
	time_sheet_unsubscribe: false,
};
var final;

const usersReducer = (state = initialState, action) => {

	switch (action.type) {
		case types.USER + '_PENDING': pending('user'); break;
		case types.USER + '_SAVE_PENDING': save_pending('user'); break;
		case types.USER + '_SAVE_FULFILLED': save_fulfilled('user'); break;
		case types.USER + '_FULFILLED': fulfilled('user'); break;

		case types.TIME_CLOCK + '_PENDING': pending('time_clock'); break;
		case types.TIME_CLOCK + '_SAVE_PENDING': save_pending('time_clock'); break;
		case types.TIME_CLOCK + '_SAVE_FULFILLED': save_fulfilled('time_clock'); break;
		case types.TIME_CLOCK + '_FULFILLED': fulfilled('time_clock'); break;

		case types.TIME_ENTRIES + '_PENDING': pending('time_entries'); break;
		case types.TIME_ENTRIES + '_SAVE_PENDING': save_pending('time_entries'); break;
		case types.TIME_ENTRIES + '_SAVE_FULFILLED': save_fulfilled('time_entries'); break;
		case types.TIME_ENTRIES + '_FULFILLED': fulfilled('time_entries'); break;

		case types.TIME_SHEET + '_PENDING': pending('time_sheet'); break;
		case types.TIME_SHEET + '_SAVE_PENDING': save_pending('time_sheet'); break;
		case types.TIME_SHEET + '_SAVE_FULFILLED': save_fulfilled('time_sheet'); break;
		case types.TIME_SHEET + '_FULFILLED': fulfilled('time_sheet'); break;

		case types.USER_PERMISSIONS + '_PENDING': pending('user_permissions'); break;
		case types.USER_PERMISSIONS + '_FULFILLED': fulfilled('user_permissions'); break;

		case types.USER_LOCATIONS + '_PENDING': pending('user_locations'); break;
		case types.USER_LOCATIONS + '_SAVE_PENDING': save_pending('user_locations'); break;
		case types.USER_LOCATIONS + '_FULFILLED': fulfilled('user_locations'); break;

		case types.USERS + '_PENDING': pending('users'); break;
		case types.USERS + '_SAVE_PENDING': save_pending('users'); break;
		case types.USERS + '_FULFILLED': fulfilled('users'); break;

		case types.USER_RECENT_ITEMS + '_PENDING': pending('user_recent_items'); break;
		case types.USER_RECENT_ITEMS + '_SAVE_PENDING': save_pending('user_recent_items'); break;
		case types.USER_RECENT_ITEMS + '_FULFILLED': fulfilled('user_recent_items'); break;

		case types.USER_ACTIVATED:

			var user = state.users[action.user.email];
			final = state.users;
			final[action.user.email] = { ...user, ...action.user };

			state = {
				...state,
				users: { ...final },
			};
			break;

		default:
	}

	return state;

	function pending(table) {
		state = {
			...state,
			[table + '_pending']: true,
		};
	}
	function save_pending(table) {
		state = {
			...state,
			[table + '_save_pending']: true,
		};
	}
	function fulfilled(table) {
		state = {
			...state,
			[table]: action.data,
			[table + '_pending']: false,
			[table + '_save_pending']: false,
			[table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
		};
	}

	function save_fulfilled(table) {
		state = {
			...state,
			[table + '_save_pending']: false,
			[table + '_saved']: action.data
		};
	}
};
export default usersReducer;


